// third-party
import { createSlice , current } from '@reduxjs/toolkit';

// project imports
import axios from '../../utils/axios';
import { dispatch } from '../index';
import { ENDPOINTS } from 'network/ENDPOINT';
import { customerRegister } from 'utils/types/requestType';
import { Users } from 'utils/types/responseType';
import { NavigateFunction } from 'react-router-dom';
import { openSnackbar } from 'store/snackbar/slice';
import { clearSelectInvoiceCustomer, getCustomersDetails } from 'store/customer/slice';
import { getCategory } from 'store/catalogue/slice';

// ----------------------------------------------------------------------
interface Tax {
    id: string;
    title: string;
    rate: number;
    taxLoading:boolean;
}
interface Invoice {
    id: string;
    amount: number;
    taxId: string;  // Foreign key or relation to tax
    date: string;
    status: string;
    // Add other fields if needed
}
interface Discount {
    id: string;
    title: string;
    rate: number;
    discountLoading:boolean;
}
interface TaxState {
    taxes: Tax[]; // Array of Tax objects
    invoices: Invoice[];
    discounts: Discount[];        
    taxLoading: boolean;  // Loading state for taxes
    discountLoading:boolean;
    isFetching: boolean;  // General fetching state
    error: string | null;
    invoiceSales:any; // To store any error messages
    paymentOptions:any;
    rewardData:any;
}
const initialState: TaxState = {
    error: null,
    invoices: [],
    taxes:[],
    taxLoading:false,
    discountLoading:false,
    discounts:[],
    isFetching:false,
    invoiceSales:[],
    paymentOptions:[],
    rewardData:null
};

const slice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {
         // HAS ERROR
         hasError(state, action) {
            state.error = action.payload;
        },
        // GET DATA INVOICE
       getSingalInvoiceSuccess(state:any,action){
        state.isFetching=false
       },
        // GET INVOICE DATA
        getInvoiceSuccess(state, action) {
            state.invoices = action.payload;
            state.isFetching=false
        },

         //ADD INVOICE DATA
        addInvoiceSuccess(state:any, action) {
            state.invoices = [action.payload, ...state.invoices];
        },
        
        //ADD TAX DATA
        addTaxSuccess(state:any, action) {
            state.taxes = [action.payload, ...state.taxes];
        },
         //ADD DISCOUNT DATA
         addDiscountSuccess(state:any, action) {
            state.discounts = [action.payload, ...state.discounts];
        },

         //  UPDATE Business Tax
         editTax(state, action) {
            const updatedTaxes = state.taxes.map(tax => {
                if (tax.id === action.payload.id) {
                    return {
                        ...tax, // Spread the current tax details
                        ...action.payload // Overwrite with new tax details from action.payload
                    };
                }
                return tax; // Return unchanged tax if IDs don't match
            });
            state.taxes = updatedTaxes;
            state.taxLoading = false;
        },
        //SET ISFETCHING
        setIsFetching(state ,action){
            state.isFetching = action.payload
       },

        // UPDATE INVOICE
        updateInvoiceList(state:any, action) {
            const temp = state.invoices?.map((item:any)=>{
                if(item?.id === action.payload){
                    return action.payload;
                }
                return item;
            }) 
            state.invoices = temp;
        },

        // DELETE INVOICE
        removeInvoiceList(state:any, action) {
            const temp = state.invoices?.filter((i:any)=> i?.id !== action.payload);
            state.invoices = temp;
        },


         // GET TAX DATA
         getTaxSuccess(state, action) {
            state.taxes = action.payload;
            state.isFetching=false
        },
        getSalesSuccess(state:any, action){
            state.invoiceSales = action.payload;
        },
         // GET DISCOUNT DATA
        getDiscountSuccess(state:any,action){
            state.discounts = action.payload;
            state.isFetching=false
        },
         //  UPDATE Business Discount
         editDiscount(state, action) {
            const updatedDiscounts = state.discounts.map(discount => {
                if (discount.id === action.payload.id) {
                    return {
                        ...discount, // Spread the current Discount details
                        ...action.payload // Overwrite with new discount details from action.payload
                    };
                }
                return discount; // Return unchanged discount if IDs don't match
            });
            state.discounts = updatedDiscounts;
            state.discountLoading = false;
        },

        setSuccessGetPaymentOption(state, action){
            state.paymentOptions = action.payload;
        },

        addPaymentSuccessSuccess(state, action){
            state.paymentOptions = [...state.paymentOptions, action.payload];
        },

        updatePaymentSuccess(state, action){
            const temp = state.paymentOptions;
            const updatedPaymentOption = temp?.map((i:any)=>{
                if(i?.id == action?.payload?.id){
                    return action.payload;
                }
                return i;
            })
            state.paymentOptions = updatedPaymentOption;
        },

        addRewardsSuccess(state, action){
            state.rewardData = action?.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getInvoiceData({companyId , search,url}:{companyId:string , search:string, url:string}) {
    return async () => {
        try {
            dispatch(slice.actions.setIsFetching(true))
            let response;
            if(search)
                response = await axios.get(`${ENDPOINTS.GET_INVOICE_LIST}?companyId=${companyId}&search=${search}`);
            else
                response = await axios.get(`${ENDPOINTS.GET_INVOICE_LIST}?companyId=${companyId}${url ? url :''}`);

            if(response?.data?.success){
                dispatch(slice.actions.getInvoiceSuccess(response.data.data.records));
            }else{
            }
        } catch (error) {
            // dispatch(slice.actions.hasError(error));
        }
    };
}

export function getInvoiceOne({id}:{id:string}) {
    return async () => {
        try {
            let response= await axios.get(`${ENDPOINTS.GET_INVOICE}/${id}`);
            if(response?.data?.success){
                dispatch(slice.actions.getSingalInvoiceSuccess(response.data.data.records));
                
            }else{
            }
        } catch (error) {
             dispatch(slice.actions.hasError(error));
        }
    };
}


export function addInvoice({payload}:{payload:any}) {
    return async () => {
        const {companyId} = payload;

        try {
            const response = await axios.post(ENDPOINTS.ADD_INVOICE,payload);
            if(response?.data?.success){
                // const url = `&id=${response.data.data?.clientId}`;
                // dispatch(getCustomersDetails({companyId:companyId,url:url}));
                dispatch(getSales({companyId}));
                dispatch(getCategory(companyId));
                dispatch(clearSelectInvoiceCustomer([]));
                dispatch(slice.actions.addInvoiceSuccess(response.data.data));
                return response;
            }
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            dispatch(openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
            return error

        }
    };
}

export function deleteInvoice({id}:{id:string}) {
    return async () => {
        try {
           const response = await axios.delete(`${ENDPOINTS.DELETE_INVOICE}/${id}`);
            if(response?.data?.success){
                dispatch(slice.actions.removeInvoiceList(id));
                dispatch(
                    openSnackbar({
                        open: true,
                        message: response?.data?.message,
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        },
                        close: false
                    })
                );
            }
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            dispatch(openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}

export function getInvoiceSingleData({companyId}:{companyId:string}) {
    return async () => {
        try {
            const response = await axios.get(`${ENDPOINTS.GET_INVOICE_LIST}?companyId=${companyId}`);
            if(response?.data?.success){
                dispatch(slice.actions.getInvoiceSuccess(response.data.data.records));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editInvoice({payload}:{payload:any}) {
    return async () => {
        try {
            const response = await axios.post(ENDPOINTS.ADD_INVOICE,payload);
            if(response?.data?.success){
                dispatch(slice.actions.updateInvoiceList(response?.data?.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        message: response?.data?.message,
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        },
                        close: false
                    })
                );
                return response;
            }else{
            }
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            dispatch(openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
            return error
        }
    };
}

export function getTaxData({companyId , search}:{companyId:string , search:string}) {
    return async () => {
        try {
            dispatch(slice.actions.setIsFetching(true))
            let response;
            if(search)
                response = await axios.get(`${ENDPOINTS.GET_TAX_DATA}?companyId=${companyId}&search=${search}`);
            else
                response = await axios.get(`${ENDPOINTS.GET_TAX_DATA}?companyId=${companyId}`);

            if(response?.data?.success){
                dispatch(slice.actions.getTaxSuccess(response.data.data.records));
            }else{
            }
        } catch (error) {
            // dispatch(slice.actions.hasError(error));
        }
    };
}
export function addTax(body:any) {
    return async (dispatch:any) => {
        try {
            const response = await axios.post(ENDPOINTS.ADD_TAX, body);
            dispatch(slice.actions.addTaxSuccess(response.data?.data));
            dispatch(openSnackbar({
                open: true,
                message: 'The new Tax has been Added successfully.',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
            dispatch(slice.actions.hasError(false));
        } catch (error:any) {
            dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))

        }
    };
}

export function updateTax(updatedData:any) {
    return async (dispatch:any) => { 
        try {
            const response = await axios.post(`${ENDPOINTS.UPSERT_TAX}`, updatedData);
            if (response && response.data) {
                dispatch(slice.actions.editTax(response.data.data));
                dispatch(openSnackbar({
                    open: true,
                    message: 'Tax updated successfully!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    },
                    close: true
                }));
            } else {
                throw new Error('Failed to update tax.');
            }
        } catch (error: any) {
            // Dispatch error state and show the snackbar with error message
            dispatch(slice.actions.hasError(true));

            dispatch(openSnackbar({
                open: true,
                message: error.response?.data?.message || error.message || 'An error occurred while updating the tax.',
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }));
        }
    };
}
    
export function getSales({companyId}:{companyId:string}) {
    return async () => {
        try {
            let response;
                response = await axios.get(`${ENDPOINTS.GET_SALES}?companyId=${companyId}`);
         
            if(response?.data?.success){
                dispatch(slice.actions.getSalesSuccess(response.data.data));
            }else{
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getDiscountList({companyId}:{companyId:string}) {
    return async () => {
        try {
            dispatch(slice.actions.setIsFetching(true))
            let response;
                response = await axios.get(`${ENDPOINTS.GET_DISCOUNT_LIST}?companyId=${companyId}`);
            if(response?.data?.success){
                dispatch(slice.actions.getDiscountSuccess(response.data.data.records));
            }else{
            }
        } catch (error) {
            // dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPaymentOptionList({companyId}:{companyId:string}) {
    return async () => {
        try {
            dispatch(slice.actions.setIsFetching(true))
            let response;
                response = await axios.get(`${ENDPOINTS.GET_PAYMENTOPTIONS}`);
            if(response?.data?.success){
                dispatch(slice.actions.setSuccessGetPaymentOption(response.data.data.records));
            }else{
            }
        } catch (error) {
            // dispatch(slice.actions.hasError(error));
        }
    };
}

export function addPaymentMethods(body:any) {
    return async (dispatch:any) => {
        try {
            const response = await axios.post(ENDPOINTS.UPSERT_PAYMENTOPTIONS, body);
            dispatch(slice.actions.addPaymentSuccessSuccess(response.data?.data));
            dispatch(openSnackbar({
                open: true,
                message: 'PaymentMethod added successfully.',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
            dispatch(slice.actions.hasError(false));
        } catch (error:any) {
            dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))

        }
    };
}

export function updatePaymentMethods(updatedData:any) {
    return async (dispatch:any) => { 
        try {
            const response = await axios.post(`${ENDPOINTS.UPSERT_PAYMENTOPTIONS}`, updatedData);
            if (response && response.data) {
                dispatch(slice.actions.updatePaymentSuccess(response.data.data));
                dispatch(openSnackbar({
                    open: true,
                    message: 'PaymentMethod updated successfully!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    },
                    close: true
                }));
            } else {
                throw new Error('Failed to update discount.');
            }
        } catch (error: any) {
            // Dispatch error state and show the snackbar with error message
            dispatch(slice.actions.hasError(true));

            dispatch(openSnackbar({
                open: true,
                message: error.response?.data?.message || error.message || 'An error occurred while updating the discount.',
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }));
        }
    };
}

export function addDiscount(body:any) {
    return async (dispatch:any) => {
        try {
            const response = await axios.post(ENDPOINTS.ADD_DISCOUNT, body);
            dispatch(slice.actions.addDiscountSuccess(response.data?.data));
            dispatch(openSnackbar({
                open: true,
                message: 'The new Discount has been Added successfully.',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
            dispatch(slice.actions.hasError(false));
        } catch (error:any) {
            dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))

        }
    };
}
export function updateDiscount(updatedData:any) {
    return async (dispatch:any) => { 
        try {
            const response = await axios.post(`${ENDPOINTS.UPSERT_DISCOUNT}`, updatedData);
            if (response && response.data) {
                dispatch(slice.actions.editDiscount(response.data.data));
                dispatch(openSnackbar({
                    open: true,
                    message: 'Discount updated successfully!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    },
                    close: true
                }));
            } else {
                throw new Error('Failed to update discount.');
            }
        } catch (error: any) {
            // Dispatch error state and show the snackbar with error message
            dispatch(slice.actions.hasError(true));

            dispatch(openSnackbar({
                open: true,
                message: error.response?.data?.message || error.message || 'An error occurred while updating the discount.',
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }));
        }
    };
}


export function addRewardsPoints(body:any) {
    return async (dispatch:any) => {
        try {
            const response = await axios.post(ENDPOINTS.UPSERT_REWARD, body);
            dispatch(slice.actions.addRewardsSuccess(response.data?.data));
            dispatch(openSnackbar({
                open: true,
                message: 'Reward points added successfully.',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
            dispatch(slice.actions.hasError(false));
        } catch (error:any) {
            dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))

        }
    };
}

export function getRewardPointsList({companyId}:{companyId:string}) {
    return async () => {
        try {
            dispatch(slice.actions.setIsFetching(true))
            let response;
                response = await axios.get(`${ENDPOINTS.GET_REWARD_POINTS_LIST}/${companyId}`);
            if(response?.data?.success){
                dispatch(slice.actions.addRewardsSuccess(response.data.data));
            }else{
            }
        } catch (error) {
            // dispatch(slice.actions.hasError(error));
        }
    };
}