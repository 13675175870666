// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import axios from "utils/axios";
import store, { dispatch } from "../index";
import { openSnackbar } from "store/snackbar/slice";
import { ENDPOINTS } from "network/ENDPOINT";
import { userName } from "utils/general";
import { selectServiceList } from "store/catalogue/slice";
import { getSchedules, getSchedulesSuccess } from "store/schedules/slice";

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  chats: [],
  user: {},
  users: [],
  commisionSettingList: [],
  employeeList: [],
  employee: {},
  isFetching: false,
  employeeServices: {},
};

const slice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET USER
    getUserSuccess(state, action) {
      state.user = action.payload;
    },

    // GET USER CHATS
    getUserChatsSuccess(state, action) {
      state.chats = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.users = action.payload;
    },
    // GET EMPLOYEE LIST
    getStaffSuccess(state, action) {
      const employees = action.payload?.users;
      state.employee =
        employees.length > 0
          ? {
              ...employees[0],
              text: `${employees[0]?.firstName} ${employees[0]?.lastName}`,
            }
          : {};

      state.employeeList = employees;
      const servicesMap = new Map();
      employees.forEach((e) => {
        const { svcCtgyItemRelation, id } = e;
        const relation = svcCtgyItemRelation.filter(
          (sr) => sr.svcCtlgItems != null
        );
        if (!servicesMap.has(id)) {
          servicesMap.set(id, relation);
        }
      });
      state.employeeServices = servicesMap;

      state.isFetching = false;
    },
    //  ADD STAFF
    addStaffSuccess(state, action) {
      state.employeeList = [action.payload, ...state.employeeList];
      const employee = {
        ...action.payload,
        text: userName(action.payload?.firstName, action.payload?.lastName),
      };
      const newEmployeeServicesMap = new Map(state.employeeServices);
      newEmployeeServicesMap.set(employee.id, []);
      state.employeeServices = newEmployeeServicesMap;
      state.employee = employee;
      state.isFetching = false;
    },

    //  UPDATE STAFF
    updateStaffSuccess(state, action) {
      const updatedData = state.employeeList?.map((i) => {
        if (i?.id === action?.payload?.id) {
          return action?.payload;
        }
        return i;
      });
      const employee = {
        ...action.payload,
        text: userName(action.payload?.firstName, action.payload?.lastName),
      };
      state.employeeList = updatedData;
      state.employee = employee;
      state.isFetching = false;
    },
    // GET TARGET COMMISION LIST
    getCommisionList(state, action) {
      state.commisionSettingList = action.payload;
      state.isFetching = false;
    },
    //  ADD TARGET COMMISION
    addCommisionSuccess(state, action) {
      state.commisionSettingList = [
        action.payload,
        ...state.commisionSettingList,
      ];
      state.isFetching = false;
    },
    //  UPDATE TARGET COMMISION
    assignCommissionSuccess(state, action) {
        state.isFetching = false;
    },

    // DELETE EMPLOYEE
    removeStaffSuccess(state, action) {
      state.employeeList = state.employeeList?.filter(
        (i) => i?.id !== action?.payload
      );
      state.isFetching = false;
    },

    assignServiceSuccess(state, action) {
      const newEmployeeServcesMap = new Map(state.employeeServices);
      const selectedCategory = action.payload.services.find(
        (c) => c.id == action.payload.catalogueId
      );
      const filteredServices = selectedCategory?.svcCtlgItems.filter((s) =>
        action.payload.serviceIds.includes(s.id)
      );

      for (let service_index in filteredServices) {
        const service = filteredServices[service_index];
        const serviceRelation = action.payload.svcCtgyItemRelation.find(
          (sr) => service.id == sr.svcCtlgItemsId
        );
        const employeeId = serviceRelation.employeeId;
        const newService = {
          id: serviceRelation?.id,
          svcCtlgItems: {
            id: service?.id,
            title: service?.title,
            status: service?.active,
            catalogueId: action.payload.catalogueId,
            price: service?.price,
            duration: service?.duration,
          },
        };
        const existingItems = newEmployeeServcesMap.get(employeeId) || [];
        newEmployeeServcesMap.set(employeeId, [...existingItems, newService]);
      }

      state.employeeServices = newEmployeeServcesMap;
    },

    removeEmployeeServiceRelationSuccess(state, action) {
      const empId = action.payload.emp;
      const newRelationMap = new Map(state.employeeServices);
      const currentServiceRelation = newRelationMap.get(empId);
      if (currentServiceRelation) {
        const updatedRelation = currentServiceRelation.filter(
          (se) => se.id != action.payload.id
        );
        newRelationMap.set(empId, updatedRelation);
      }

      state.employeeServices = newRelationMap;
    },

    //SET ISFETCHING
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    //SET EMPLOYEE
    setEmployeeSuccess(state, action) {
      state.employee = action.payload;
    },

    updateEmployeServicesSuccess(state, action) {
      const existingEmployeeServices = new Map(state.employeeServices);
      existingEmployeeServices.forEach((services, key) => {
        const updatedArray = services.map((s) => {
          if (action.payload.id == s?.svcCtlgItems?.id) {
            return {
              ...s,
              svcCtlgItems: action.payload,
            };
          }
          return s;
        });

        existingEmployeeServices.set(key, updatedArray);
      });
      state.employeeServices = existingEmployeeServices;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function updateEmployeServices(data) {
  return async () => {
    dispatch(slice.actions.updateEmployeServicesSuccess(data));
  };
}

export function getUser(id) {
  return async () => {
    try {
      const response = await axios.post("/api/chat/users/id", { id });
      dispatch(slice.actions.getUserSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserChats(user) {
  return async () => {
    try {
      const response = await axios.post("/api/chat/filter", { user });
      dispatch(slice.actions.getUserChatsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function insertChat(chat) {
  return async () => {
    try {
      await axios.post("/api/chat/insert", chat);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsers() {
  return async () => {
    try {
      const response = await axios.get("/api/chat/users");
      dispatch(slice.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStaffs(companyId) {
  return async () => {
    try {
      dispatch(slice.actions.setIsFetching(true));
      const response = await axios.get(
        `${ENDPOINTS.USER_API}?companyId=${companyId}&role=employee`
      );
      dispatch(slice.actions.getStaffSuccess(response?.data?.data));
      dispatch(getSchedules({ companyId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(
        openSnackbar({
          open: true,
          message: error.message || error.error,
          variant: "alert",
          alert: {
            color: "error",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    }
  };
}

export function addStaff(payload) {
  return async () => {
    try {
      dispatch(slice.actions.setIsFetching(true));
      const { body, companyId } = payload;
      const response = await axios.post(
        `${ENDPOINTS.ADD_EMPLOYEE_API}/${companyId}`,
        body
      );
      dispatch(slice.actions.addStaffSuccess(response.data.data));
      dispatch(getSchedules({ companyId }));
      dispatch(slice.actions.hasError(false));
      dispatch(
        openSnackbar({
          open: true,
          message: "The new staff has been added successfully.",
          variant: "alert",
          alert: {
            color: "success",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    } catch (error) {
      dispatch(slice.actions.setIsFetching(false));
      dispatch(slice.actions.hasError(true));
      dispatch(
        openSnackbar({
          open: true,
          message: error.message || error.error,
          variant: "alert",
          alert: {
            color: "error",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    }
  };
}

export function updateStaff(payload) {
  return async () => {
    try {
      dispatch(slice.actions.setIsFetching(true));
      const { body, companyId } = payload;
      const response = await axios.post(
        `${ENDPOINTS.ADD_EMPLOYEE_API}/${companyId}`,
        body
      );
      dispatch(slice.actions.updateStaffSuccess(response.data.data));
      dispatch(
        openSnackbar({
          open: true,
          message: "The selected staff has been updated successfully.",
          variant: "alert",
          alert: {
            color: "success",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(true));
      dispatch(
        openSnackbar({
          open: true,
          message: error.message || error.error,
          variant: "alert",
          alert: {
            color: "error",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    }
  };
}

export function deleteStaff({ id }) {
  return async () => {
    try {
      dispatch(slice.actions.setIsFetching(true));
      const response = await axios.delete(`${ENDPOINTS.DELETE_EMPLOYEE}/${id}`);
      dispatch(slice.actions.removeStaffSuccess(id));
      dispatch(slice.actions.hasError(false));
      dispatch(
        openSnackbar({
          open: true,
          message: "The selected employee has been deleted successfully.",
          variant: "alert",
          alert: {
            color: "success",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    } catch (error) {
      dispatch(slice.actions.setIsFetching(false));
      dispatch(slice.actions.hasError(true));
      dispatch(
        openSnackbar({
          open: true,
          message: error.message,
          variant: "alert",
          alert: {
            color: "error",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    }
  };
}

export function assignServices(data) {
  return async () => {
    try {
      const rootState = store.getState();
      const servciceList = selectServiceList(rootState);
      dispatch(
        slice.actions.assignServiceSuccess({ ...data, services: servciceList })
      );
    } catch (err) {
      dispatch(
        openSnackbar({
          open: true,
          message: err,
          variant: "alert",
          alert: {
            color: "error",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    }
  };
}

export function removeEmployeeServiceRelation(data) {
  return async () => {
    try {
      dispatch(slice.actions.removeEmployeeServiceRelationSuccess(data));
    } catch (err) {
      console.log("err", err);
    }
  };
}

export function setEmployee(employee) {
  return async () => {
    try {
      dispatch(slice.actions.setEmployeeSuccess(employee));
    } catch (err) {
      dispatch(
        openSnackbar({
          open: true,
          message: err,
          variant: "alert",
          alert: {
            color: "error",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    }
  };
}
export function getTargetcommisionList(companyId) {
  return async () => {
    try {
      dispatch(slice.actions.setIsFetching(true));
      const response = await axios.get(
        `${ENDPOINTS.GET_TARGET_COMMISION_LIST}?companyId=${companyId}`
      );
      dispatch(slice.actions.getCommisionList(response?.data?.data?.records));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(
        openSnackbar({
          open: true,
          message: error.message || error.error,
          variant: "alert",
          alert: {
            color: "error",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    }
  };
}
export function assignCommissionToStaff(payload) {
  return async () => {
    try {
      dispatch(slice.actions.setIsFetching(true));
      const response = await axios.post(
        ENDPOINTS.ASSIGNMENT_TARGET_COMMISION,
        payload
      );
      dispatch(slice.actions.assignCommissionSuccess(response.data.data));
      dispatch(
        openSnackbar({
          open: true,
          message: "The selected commision has been assigned successfully.",
          variant: "alert",
          alert: {
            color: "success",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(true));
      dispatch(
        openSnackbar({
          open: true,
          message: error.message || error.error,
          variant: "alert",
          alert: {
            color: "error",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    }
  };
}

export function addTargetCommision(body) {
  return async () => {
    try {
      dispatch(slice.actions.setIsFetching(true));
      const response = await axios.post(ENDPOINTS.ADD_TARGET_COMMISION, body);
      dispatch(slice.actions.addCommisionSuccess(response?.data?.data));
      dispatch(slice.actions.hasError(false));
      dispatch(
        openSnackbar({
          open: true,
          message: "The new commision has been created successfully.",
          variant: "alert",
          alert: {
            color: "success",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    } catch (error) {
      dispatch(slice.actions.setIsFetching(false));
      dispatch(slice.actions.hasError(true));
      dispatch(
        openSnackbar({
          open: true,
          message: error.message || error.error,
          variant: "alert",
          alert: {
            color: "error",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    }
  };
}
