import {
  Grid,
  Tab,
  //  Tabs
} from "@mui/material";
import React, {
  useEffect,
  //  useCallback, useEffect,
  useState,
} from "react";
// import PersonAddTwoTone from "@mui/icons-material/PersonAddTwoTone";
import PropTypes from "prop-types";
import "modules/@mobiscroll/react/css/mobiscroll.min.css";
// material-ui
import Box from "@mui/material/Box";
import {
  Drawer,
  //  Typography,
  DialogTitle,
  Divider,
} from "@mui/material";
import InvoiceHistoryTable from "pages/users/customers/InvoiceHistoryTable";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { AppointmentHistoryTable } from "pages/users/customers/AppointmentHistoryTable";
// import { useSelector } from "react-redux";
import InvoiceCatelogueItemList from "components/invoice/Create/InvoiceCatelogueItemList";
import MembershipHistoryTable from "pages/users/customers/MembershipHistoryTable";
import WalletTransactionTable from "pages/users/customers/WalletTransactionTable";
import { useSelector } from "react-redux";
import { v4 as uuid } from 'uuid'

export const CustomerHistory = ({ open, onClose, clientDetail }) => {
  const [value, setValue] = useState("0");
  const [seletcedClientPackageList, setSelectedClientPackageList] = useState();
  const { customers } = useSelector((state) => state.customer);

  useEffect(() => {
    if (customers?.length > 0) {
      const temp = customers?.find((i) => i?.id === clientDetail?.id);

      const invDetailMap = temp?.invDetailPkgItmRltn?.reduce((acc, detail) => {
        acc[detail.packageItemId] = detail.isRedeemed;
        return acc;
      }, {});

      const tempData = temp?.packageRelation?.map((packageRel) => {
        const data = packageRel?.package?.packageItems.map((item) => {
          if (invDetailMap[item.id] !== undefined) {
            return {
              ...item,
              isServiceRedeemed: invDetailMap[item?.id],
            };
          }
        });
        return {
          ...packageRel,
          package: { ...packageRel.package, packageItems: data },
        };
      });
      setSelectedClientPackageList(tempData ?? []);
    }
  }, [clientDetail]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    {
      label: "Package",
      visible: clientDetail?.packageRelation?.length > 0,
      content: (
        <InvoiceCatelogueItemList packageRelation={seletcedClientPackageList} />
      ),
    },
    {
      label: "Invoice",
      visible: true,
      content: <InvoiceHistoryTable clientDetail={clientDetail} />,
    },
    {
      label: "Membership",
      visible: clientDetail?.membershipPlansRltn?.length > 0,
      content: (
        <MembershipHistoryTable
          membershipPlansRltn={clientDetail?.membershipPlansRltn}
        />
      ),
    },
    {
      label: "Wallet Transaction",
      visible: clientDetail?.wallets?.walletTransactions?.length > 0,
      content: <WalletTransactionTable wallets={clientDetail?.wallets} />,
    },
    {
      label: "Appointment",
      visible: true,
      content: <AppointmentHistoryTable clientDetail={clientDetail} />,
    },
  ];

  const historyTabs = tabs.filter((tab) => tab.visible);
  return (
    <Drawer
      sx={{
        ml: open ? 3 : 0,
        flexShrink: 0,
        zIndex: 1200,
        overflowX: "hidden",
        width: { xs: 600, md: 1000 },
        "& .MuiDrawer-paper": {
          height: "100vh",
          width: { xs: 600, md: 1000 },
          position: "fixed",
          border: "none",
          borderRadius: "0px",
        },
      }}
      variant="temporary"
      anchor="right"
      open={open}
      ModalProps={{ keepMounted: true }}
      onClose={() => {
        onClose();
      }}
    >
      <Grid item sx={{ width: "calc(100% - 50px)" }}>
        <DialogTitle>Client History</DialogTitle>
      </Grid>
      <Divider />
      <Box sx={{ width: "100%", typography: "body1", padding: "25px" }}>
        <TabContext value={value}>
          <Box
            sx={{
              position: "relative",
              marginTop: "-74px",
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <TabList onChange={handleChange} aria-label="dynamic tabs example">
              {historyTabs?.map((tab, index) => (
                <Tab
                  key={uuid()}
                  sx={{
                    textTransform: "uppercase",
                    letterSpacing: "0.03rem",
                  }}
                  label={tab.label}
                  value={index.toString()}
                />
              ))}
            </TabList>
          </Box>
        

          <TabPanel value={value}>{historyTabs[value]?.content}</TabPanel>
        </TabContext>
      </Box>
    </Drawer>
  );
};

CustomerHistory.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  clientDetail: PropTypes.object,
};
