import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";

function InvoiceDiscount({ grossPrice, handleAllAmounts, setTaxValue, discountValue }) {
  const [invoiceDiscount, setInvoiceDiscount] = useState("");
  const [currencyType, setCurrencyType] = useState("INR");

  // useEffect(() => {
  //   if(discountValue){
  //     setInvoiceDiscount(discountValue);
  //   }
  // }, [discountValue])
  

  const handleInvoiceDiscountChange = (e) => {
    const value = +e.target.value;
    setInvoiceDiscount(e.target.value);
    let discount = 0;
    if (currencyType === "INR") {
      discount = value;
    } else {
      discount = (value * grossPrice) / 100;
    }

    handleAllAmounts("invoiceDiscount", discount);
    setTaxValue(null);
    // if (discount > grossPrice) {
    //   alert("oye kya kr rha h ! itna discount kon deta h ");
    // }
  };

  const handleCurrencyTypeChange = (e) => {
    setCurrencyType(e.target.value);
    setInvoiceDiscount("");
    handleAllAmounts("invoiceDiscount", 0);
    setTaxValue(null);
  };

  return (
    <>
      <InputLabel> Discount </InputLabel>
      <FormControl fullWidth variant="outlined">
        <TextField
          type="number"
          value={invoiceDiscount}
          onChange={handleInvoiceDiscountChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Select
                  value={currencyType}
                  onChange={handleCurrencyTypeChange}
                  sx={{
                    border: "none",
                    height: "100%",
                    padding: "0",
                    minWidth: "auto",
                    "& .MuiSelect-icon": {
                      right: "2px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Currency Type",
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        borderRadius: "2px",
                        maxHeight: "200px",
                      },
                    },
                  }}
                >
                  <MenuItem
                    value="INR"
                    sx={{ fontSize: "16px", padding: "10px 20px" }}
                  >
                    INR
                  </MenuItem>
                  <MenuItem
                    value="PERCENTAGE"
                    sx={{
                      fontSize: "16px",
                      padding: "10px 25px",
                      borderRadius: "4px",
                    }}
                  >
                    %
                  </MenuItem>
                </Select>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </>
  );
}

export default InvoiceDiscount;
