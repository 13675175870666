// third-party
import { createSlice , current } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { ENDPOINTS } from 'network/ENDPOINT';
import dayjs from 'dayjs';
import { serviceTabsValue } from 'utils/constantString';
import { openSnackbar } from 'store/snackbar/slice';
import { assignServices, removeEmployeeServiceRelation, updateEmployeServices } from 'store/employees/slice';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    dashboardData:[],
    isFetching:false,
};

const slice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;

        },
   
        getDashboardSuccess(state, action){
            state.dashboardData = action.payload;
        },

        setIsFetching(state, action){
            state.isFetching = action.payload;
        }
    }
});

export function getDashboard({companyId,startDate,endDate}) {
    return async () => {
        try {
            dispatch(slice.actions.setIsFetching(true))
            const response = axios.get(`${ENDPOINTS.GET_DASHBOARD}?companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`);
            Promise.resolve(response)
            .then((results) => {
                dispatch(slice.actions.getDashboardSuccess((results.data.data)))
                dispatch(slice.actions.setIsFetching(false));
            });
        }catch (error) {
            dispatch(slice.actions.setIsFetching(false))
            dispatch(slice.actions.hasError(error));
        }
    };
}


// Reducer
export default slice.reducer;