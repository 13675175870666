import * as React from "react";
import { Autocomplete, TextField } from "@mui/material";

export default function ItemAutoComplete({
  label,
  value,
  options,
  getOptionLabel,
  handleChange,
  groupBy,
  renderGroup,
  id
}) {
  return (
    <>
      <label>{label}</label>
      <Autocomplete
        sx={{ mt: 1 }}
        value={value}
        id={id}
        groupBy={groupBy}
        onChange={handleChange}
        renderGroup={renderGroup}
        options={options}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => <TextField {...params} />}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        fullWidth            
      />
    </>
  );
}
