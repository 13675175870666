// third-party
import { FormattedMessage } from 'react-intl';
// assets
import {
  IconApps,
  IconUserCheck,
  IconBasket,
  IconFileInvoice,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
  IconCategory,
  IconDeviceAnalytics,
  IconPlaylistAdd,
  IconTable,
  IconBorderAll,
  IconSettingsCheck,
  IconUsersGroup,
  IconCurrencyRupee,
  IconReportAnalytics,
  // IconOctagonPlus
} from "@tabler/icons-react";


// constant
const icons = {
  IconApps,
  IconUserCheck,
  IconBasket,
  IconFileInvoice,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
  IconCategory,
  IconDeviceAnalytics,
  IconPlaylistAdd,
  IconTable,
  IconBorderAll,
  IconSettingsCheck,
  IconUsersGroup,
  IconCurrencyRupee,
  IconReportAnalytics,
  // IconOctagonPlus,
};

// ==============================|| MENU ITEMS - APPLICATION ||============================== //

const appointimize = {
  id: "appointimize",
  icon: icons.IconApps,
  type: "group",
  children: [
    {
      id: "analytics",
      title: <FormattedMessage id="analytics" />,
      type: "item",
      url: "/dashboard/analytics",
      icon: icons.IconDeviceAnalytics,
      breadcrumbs: false,
    },
    {
      id: "dashboard",
      title: <FormattedMessage id="dashboard" />,
      type: "item",
      url: "/dashboard",
      icon: icons.IconDeviceAnalytics,
      breadcrumbs: false,
    },
    // {
    //   id: "addInvoice",
    //   title: <FormattedMessage id="Add Invoice" />,
    //   type: "item",
    //   icon: icons.IconOctagonPlus,
    //   url: "/invoices/generate-invoice",
    //   breadcrumbs: false,
    // },
    // {
    //   id: "newInvoice",
    //   title: <FormattedMessage id="Add Invoice" />,
    //   type: "item",
    //   icon: icons.IconOctagonPlus,
    //   url: "/create-invoice",
    //   breadcrumbs: false,
    // },
    {
      id: "invoice",
      title: <FormattedMessage id="Invoice List" />,
      type: "item",
      icon: icons.IconFileInvoice,
      url: "/invoices/invoice-list/",
      breadcrumbs: false,
    },
    {
      id: "expense",
      title: <FormattedMessage id="Expense" />,
      type: "item",
      icon: icons.IconCurrencyRupee,
      url: "/expense",
      breadcrumbs: false,
    },
    {
      id: "sales-lead",
      title: <FormattedMessage id="Sales Lead" />,
      type: "item",
      icon: icons.IconReportAnalytics,
      url: "/sales-lead",
      breadcrumbs: false,
    },
    {
      id: "client",
      title: <FormattedMessage id="tab.client" defaultMessage="Client" />,
      type: "item",
      url: "/customers/list",
      icon: icons.IconBasket,
      breadcrumbs: false,
    },
    {
      id: "staff",
      title: <FormattedMessage id="staff" defaultMessage={"Staff"} />,
      type: "collapse",
      icon: icons.IconUsersGroup,
      children: [
        {
          id: "staff-list",
          title: <FormattedMessage id="staff-list" defaultMessage={"List"} />,
          type: "item",
          url: "/employees/list",
          breadcrumbs: false,
        },
        {
          id: "roster",
          title: <FormattedMessage id="roster" defaultMessage={"Schedule"} />,
          type: "item",
          url: "/employees/roster/",
          breadcrumbs: false,
        },
        {
          id: "commission",
          title:"Commission Setting",
          type:"item",
          url:"/employees/commission-setting",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "calendar",
      title: <FormattedMessage id="calendar" />,
      type: "item",
      url: "/appointments/calender-view/",
      icon: icons.IconCalendar,
      breadcrumbs: false,
    },
    {
      id: "catalogue",
      title: <FormattedMessage id="catalogue" />,
      type: "collapse",
      icon: icons.IconCategory,
      children: [
        {
          id: "services",
          title: <FormattedMessage id="services" />,
          type: "item",
          url: "/catalogue/services/",
          breadcrumbs: false,
        },
        {
          id: "products",
          title: <FormattedMessage id="products" />,
          type: "item",
          url: "/catalogue/products/",
          breadcrumbs: false,
        },
        {
          id: "packages",
          title: <FormattedMessage id="packages" defaultMessage={"Packages"} />,
          type: "item",
          url: "/catalogue/packages/",
          breadcrumbs: false,
        },
        {
          id: "Membership Wallet",
          title: (
            <FormattedMessage id="membership" defaultMessage={"Membership"} />
          ),
          type: "item",
          url: "/catalogue/membership/",
          breadcrumbs: false,
        },
      ],
    },

    /*  // {
        //     id: 'employees',
        //     title: <FormattedMessage id="employees" />,
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     breadcrumbs: false,
        //     children: [
        //         {
        //             id: 'list',
        //             title: <FormattedMessage id="list" />,
        //             type: 'item',
        //             url: '/employees/list',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'create',
        //             title: <FormattedMessage id="create" />,
        //             icon: icons.IconPlaylistAdd,
        //             type: 'item',
        //             url: '/employees/list',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'staff',
        //             title: <FormattedMessage id="staff" />,
        //             type: 'item',
        //             url: '/employees/staff',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        // {
        //     id: 'customer',
        //     title: <FormattedMessage id="customer" />,
        //     type: 'collapse',
        //     icon: icons.IconBasket,
        //     children: [
        //         {
        //             id: 'customer-list',
        //             title: <FormattedMessage id="customer-list" />,
        //             type: 'item',
        //             url: '/customer/customer-list/'
        //         },
        //         {
        //             id: 'order-list',
        //             title: <FormattedMessage id="order-list" />,
        //             type: 'item',
        //             url: '/customer/order-list'
        //         }
        //     ]
        // },
        // {
        //     id: 'tables',
        //     title: <FormattedMessage id="table" />,
        //     type: 'collapse',
        //     icon: icons.IconBorderAll,
        //     children: [
        //         {
        //             id: 'tbl-basic',
        //             title: <FormattedMessage id="table-basic" />,
        //             type: 'item',
        //             url: '/tables/tbl-basic',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'tbl-dense',
        //             title: <FormattedMessage id="table-dense" />,
        //             type: 'item',
        //             url: '/tables/tbl-dense',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'tbl-enhanced',
        //             title: <FormattedMessage id="table-enhanced" />,
        //             type: 'item',
        //             url: '/tables/tbl-enhanced',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'tbl-data',
        //             title: <FormattedMessage id="table-data" />,
        //             type: 'item',
        //             url: '/tables/tbl-data',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'tbl-customized',
        //             title: <FormattedMessage id="table-customized" />,
        //             type: 'item',
        //             url: '/tables/tbl-customized',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'tbl-sticky-header',
        //             title: <FormattedMessage id="table-sticky-header" />,
        //             type: 'item',
        //             url: '/tables/tbl-sticky-header',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'tbl-collapse',
        //             title: <FormattedMessage id="table-collapse" />,
        //             type: 'item',
        //             url: '/tables/tbl-collapse',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        {
            id: 'data-grid',
            title: <FormattedMessage id="data-grid" />,
            type: 'collapse',
            icon: icons.IconTable,
            children: [
                {
                    id: 'data-grid-basic',
                    title: <FormattedMessage id="data-grid-basic" />,
                    type: 'item',
                    url: '/data-grid/data-grid-basic',
                    breadcrumbs: false
                },
                {
                    id: 'data-grid-inline-editing',
                    title: <FormattedMessage id="data-grid-inline-editing" />,
                    type: 'item',
                    url: '/data-grid/data-grid-inline-editing',
                    breadcrumbs: false
                },
                {
                    id: 'data-grid-column-groups',
                    title: <FormattedMessage id="data-grid-column-groups" />,
                    type: 'item',
                    url: '/data-grid/data-grid-column-groups',
                    breadcrumbs: false
                },
                {
                    id: 'data-grid-save-restore',
                    title: <FormattedMessage id="data-grid-save-restore" />,
                    type: 'item',
                    url: '/data-grid/data-grid-save-restore',
                    breadcrumbs: false
                },
                {
                    id: 'data-grid-quick-filter',
                    title: <FormattedMessage id="data-grid-quick-filter" />,
                    type: 'item',
                    url: '/data-grid/data-grid-quick-filter',
                    breadcrumbs: false
                },
                {
                    id: 'data-grid-column-visibility',
                    title: <FormattedMessage id="data-grid-column-visibility" />,
                    type: 'item',
                    url: '/data-grid/data-grid-column-visibility',
                    breadcrumbs: false
                },
                {
                    id: 'data-grid-column-virtualization',
                    title: <FormattedMessage id="data-grid-column-virtualization" />,
                    type: 'item',
                    url: '/data-grid/data-grid-column-virtualization',
                    breadcrumbs: false
                },
                {
                    id: 'data-grid-column-menu',
                    title: <FormattedMessage id="data-grid-column-menu" />,
                    type: 'item',
                    url: '/data-grid/data-grid-column-menu',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'calendar',
            title: <FormattedMessage id="calendar" />,
            type: 'item',
            url: '/create-appointment',
            icon: icons.IconCalendar
        },
        */

    {
      id: "users",
      title: (
        <FormattedMessage
          id="account-settings"
          defaultMessage="Business Settings"
        />
      ),
      type: "item",
      icon: icons.IconSettingsCheck,
      url: "/business/account-settings/",
      // children: [
      //     {
      //         id: 'account-profile',
      //         title: <FormattedMessage id="account-profile" />,
      //         type: 'collapse',
      //         children: [
      //             {
      //                 id: 'profile1',
      //                 title: (
      //                     <>
      //                         <FormattedMessage id="profile" /> 01
      //                     </>
      //                 ),
      //                 type: 'item',
      //                 url: '/apps/user/account-profile/profile1'
      //             },
      //             {
      //                 id: 'profile2',
      //                 title: (
      //                     <>
      //                         <FormattedMessage id="profile" /> 02
      //                     </>
      //                 ),
      //                 type: 'item',
      //                 url: '/apps/user/account-profile/profile2'
      //             },
      //             {
      //                 id: 'profile3',
      //                 title: (
      //                     <>
      //                         <FormattedMessage id="profile" /> 03
      //                     </>
      //                 ),
      //                 type: 'item',
      //                 url: '/apps/user/account-profile/profile3'
      //             }
      //         ]
      //     }
      // ]
    },
  ],
};

export default appointimize;
