import * as React from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  ListItemText,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import AddContactDialog from "pages/clients/ContactList/AddContactDialog";
import { doesNotContainsAlpha } from "utils/general";

export default function ClientSelectBox({ client, handleChange, setClient, invoiceDetails }) {
  const { customers } = useSelector((store) => store.customer);

  const [open, setOpen] = React.useState(false);
  const [number, setNumber] = React.useState("");
  return (
    <>
      <AddContactDialog
        open={open}
        handleToggleAddDialog={() => setOpen(false)}
        handleSelectClient={(val) => setClient(val)}
        inputValue={number}
      />
      <FormControl fullWidth style={{ padding: "10px 5px 10px 10px" }}>
        <p
          style={{
            margin: "0px 0px 4px 0px",
            fontWeight: "500",

            color: "#9469FC",
          }}
        >
          PLEASE SELECT CLIENT
        </p>
        <Autocomplete
          value={client}
          disabled={invoiceDetails?.length > 0}
          onChange={handleChange}
          options={customers}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          filterOptions={(options, { inputValue }) => {
            const filteredOptions = options.filter(
              (option) =>
                option.firstName
                  .toLowerCase()
                  .includes(inputValue.toLowerCase()) ||
                option.lastName
                  .toLowerCase()
                  .includes(inputValue.toLowerCase()) ||
                option.phoneNumber.startsWith(inputValue)
            );
            if (
              filteredOptions.length === 0 &&
              inputValue.length === 10 &&
              doesNotContainsAlpha(inputValue)
            ) {
              setNumber(inputValue);
              setOpen(true);
            }
            return filteredOptions;
          }}
          renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            return (
              <Box
                key={key}
                component="li"
                sx={{ height: "3rem" }}
                {...optionProps}
              >
                <ListItemText
                  primary={`${option.firstName} ${option.lastName}`}
                  secondary={`(+91) ${option?.phoneNumber}`}
                />
                {/* {`${option.label} -  +${option.phoneNumber}`} */}
              </Box>
            );
          }}
          renderInput={(params) => <TextField placeholder="" {...params} />}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          fullWidth
        />
      </FormControl>
    </>
  );
}
