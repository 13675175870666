import React, { useState } from "react";
import ItemAutoComplete from "./ItemAutoComplete";
import SelectEmployeeAutoComplete from "./SelectEmployeeAutoComplete";
import { useSelector } from "react-redux";
import { Box, Button, Divider, Grid, TextField, styled } from "@mui/material";
import { lighten, darken } from "@mui/material";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});


const MembershipReedemInvoiceForm = ({
  invoiceDetailsItem,
  setInvoiceDetailsItem,
  handleInvoice,
  setDrawer,
  membershipRedeemOptions,
  setInvoiceDetails,
}) => {
  const [membershipData, setMembershipData] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);


  const { serviceList, productList } = useSelector(
    (state) => state.catalogue
  );

  const handleEmployeeSelect = (employees) => {
    handleInvoice("employees", employees);
  };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <ItemAutoComplete
            label="Item Name"
            id="grouped-item"
            options={membershipRedeemOptions}
            value={invoiceDetailsItem?.currItem}
            getOptionLabel={(option) => {
              return option.titleName;
            }}
            groupBy={(option) => option?.membershipName?.toUpperCase()}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            handleChange={(event, currItem) => {
              if(currItem?.title === "service") {
                let serviceMembershipData = null;
                serviceList?.map((i) => {
                  let data = i?.svcCtlgItems?.find((i) => {
                    return i?.id === currItem?.titleId;
                  });
                  if (data) {
                    serviceMembershipData = data;
                  }
                });
                setMembershipData({
                  ...serviceMembershipData,
                  type: currItem?.type,
                  discount: currItem?.discount,
                });
                if (currItem) {
                  setInvoiceDetailsItem((prev) => {
                    return {
                      ...prev,
                      itemPrice: serviceMembershipData?.price,
                      itemAmount:
                        parseFloat(serviceMembershipData?.price) -
                        (parseFloat(serviceMembershipData?.price) *
                          currItem?.discount) /
                          100,
                      itemId: currItem?.titleId,
                      itemQuantity: 1,
                      currItem: { title: serviceMembershipData?.title },
                      itemType: currItem?.title,
                    };
                  });
                }
                setTotalAmount(parseFloat(serviceMembershipData?.price) -
                (parseFloat(serviceMembershipData?.price) *
                  currItem?.discount) /
                  100);
                
              }
              if (currItem?.title === "product") {
                let productMembershipData = null;
                productList?.map((i) => {
                  let data = i?.productCatalogues?.find((i) => {
                    return i?.id === currItem?.titleId;
                  });
                  if (data) {
                    productMembershipData = data;
                  }
                });

                setMembershipData({
                  ...productMembershipData,
                  type: currItem?.type,
                  discount: currItem?.discount,
                });
                if (currItem) {
                  setInvoiceDetailsItem((prev) => {
                    return {
                      ...prev,
                      itemPrice: productMembershipData?.price,
                      itemAmount:
                        parseFloat(productMembershipData?.price) -
                        parseFloat(productMembershipData?.price) *
                          (currItem?.discount / 100),
                      itemId: currItem?.titleId,
                      itemQuantity: 1,
                      currItem: { title: productMembershipData?.title },
                      itemType: currItem?.type,
                    };
                  });
                }
              
              }
            }}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <div style={{ marginTop: "5px" }}>
            <label>Actual Price</label>
            <TextField
              disabled={true}
              sx={{ mt: 1 }}
              variant="outlined"
              fullWidth
              type="number"
              value={membershipData?.price}
              onChange={(event) => {
                handleInvoice("itemAmount", Number(event.target.value));
              }}
              InputProps={{
                startAdornment: <span>₹</span>,
              }}
              required
            />
          </div>
        </Grid>

        <Grid item sm={12} md={6}>
          <div style={{ marginTop: "5px" }}>
            <label>Discount</label>
            <TextField
              disabled={true}
              sx={{ mt: 1 }}
              variant="outlined"
              fullWidth
              type="number"
              value={membershipData?.discount}
              onChange={(event) => {
                handleInvoice("itemDiscount", Number(event.target.value));
              }}
              InputProps={{
                startAdornment: <span>₹</span>,
              }}
              required
            />
          </div>
        </Grid>

        <Grid item sm={12} md={6}>
          <div style={{ marginTop: "5px" }}>
            <label>Discounted Price</label>
            <TextField
              disabled={true}
              sx={{ mt: 1 }}
              variant="outlined"
              fullWidth
              type="number"
              value={
                parseFloat(membershipData?.price) -
                (parseFloat(membershipData?.price) * membershipData?.discount) /
                  100
              }
              onChange={(event) => {
                handleInvoice(
                  "itemAmount",
                  parseFloat(membershipData?.price) -
                    (parseFloat(membershipData?.price) *
                      membershipData?.discount) /
                      100
                );
              }}
              InputProps={{
                startAdornment: <span>₹</span>,
              }}
              required
            />
          </div>
        </Grid>

        <Grid item sm={12} md={6}>
          <div style={{ marginTop: "5px" }}>
            <label>Quantity</label>
            <TextField
              sx={{ mt: 1 }}
              variant="outlined"
              fullWidth
              type="number"
              // value={invoiceDetailsItem?.itemQuantity}
              onChange={(event) => {
                handleInvoice("itemQuantity", Number(event.target.value));
                if(event.target.value == 1){
                  setTotalAmount(parseFloat(invoiceDetailsItem?.itemPrice) - (parseFloat(invoiceDetailsItem?.itemPrice) * (membershipData?.discount) / 100));
                }else if(event.target.value > 1){
                  setTotalAmount( (parseFloat(invoiceDetailsItem?.itemPrice) - (parseFloat(invoiceDetailsItem?.itemPrice) * (membershipData?.discount) / 100)) * Number(event.target.value));
                }else{
                  setTotalAmount(0);
                }
               
              }}
              required
            />
          </div>
        </Grid>
        <Grid item sm={12} md={6}>
          <div style={{ marginTop: "5px" }}>
            <label>Select Employee</label>
            <SelectEmployeeAutoComplete
              invoiceDetailsItemEmployees={invoiceDetailsItem?.employees}
              handleChange={handleEmployeeSelect}
            />
          </div>
        </Grid>
      </Grid>

      <br />
      <br />

      <Divider />

      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        marginTop="30px"
      >
        <div>TOTAL : ₹{totalAmount}</div>
        <div style={{ display: "flex", gap: "5px" }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setDrawer({ open: false });
              setInvoiceDetailsItem(null);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            // disable if no employee is choosen
            // disabled={invoiceDetailsItem?.employees?.length < 1 ? true : false}
            onClick={() => {
              if (
                !invoiceDetailsItem?.employees ||
                invoiceDetailsItem?.employees?.length === 0
              ) {
                const rowData = handleInvoice("employees", []);
                setInvoiceDetails((prev) => {
                  return [
                    ...prev,
                    {
                      ...rowData,
                      total:
                        invoiceDetailsItem.itemAmount *
                        invoiceDetailsItem.itemQuantity,
                      uuid: new Date(),
                    },
                  ];
                });
                setDrawer({ open: false });
                setInvoiceDetailsItem(null);
              } else {
                setDrawer({
                  open: true,
                  drawerWidth: "40%",
                  title: "Revenue and Commission",
                  showIcon: "",
                  type: "CommisionAndRevenue",
                });

                const data = {
                  ...invoiceDetailsItem,
                  total:
                    invoiceDetailsItem.itemAmount *
                    invoiceDetailsItem.itemQuantity,
                  employees: invoiceDetailsItem?.employees?.map((item) => {
                    return {
                      ...item,
                      revenue: (
                        100 / invoiceDetailsItem?.employees?.length
                      ).toFixed(2),
                      commission: 0,
                    };
                  }),
                };
                setInvoiceDetailsItem(data);
              }
            }}
          >
            PROCEED
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default MembershipReedemInvoiceForm;
