import {
  Autocomplete,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const InvoiceTaxField = ({
  taxValue,
  setTaxValue,
  taxableAmount,
  handleAllAmounts,
}) => {
  const { taxes } = useSelector((state) => state.invoice);
  return (
    <>
      <InputLabel> Tax </InputLabel>

      <FormControl fullWidth>
        <Autocomplete
          fullWidth
          options={taxes}
          value={taxValue}
          getOptionLabel={(option) => {
            if (!option) return "";
            return `${option?.title}  (${parseInt(option?.rate)}%)`;
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select Tax" />
          )}
          onChange={(e, v, r) => {
            setTaxValue(v);
            const taxAmount = v?.rate
              ? (parseFloat(v?.rate) * taxableAmount) / 100
              : 0;
            handleAllAmounts("invoiceTaxAmount", taxAmount);
          }}
        />
      </FormControl>
    </>
  );
};

export default InvoiceTaxField;
