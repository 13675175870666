import React, { useEffect, useMemo, useState } from "react";
import "./style.css";
import { FormControl, Grid } from "@mui/material";
import InvoiceItemsTable from "./invoice-components/InvoiceItemsTable";
import CategoryTypeComponent from "./invoice-components/CategoryTypeComponent";
import NewCommonDrawer from "./invoice-components/NewCommonDrawer";
import ClientSelectBox from "./invoice-components/ClientSelctBox";
import CommisionAndRevenue from "./invoice-components/CommisionAndRevenue";
import ServiceInvoiceForm from "./invoice-components/ServiceInvoiceForm";
import ProductInvoiceForm from "./invoice-components/ProductInvoiceForm";
import PackageInvoiceForm from "./invoice-components/PackageInvoiceForm";
import MembershipInvoiceForm from "./invoice-components/MembershipInvoiceForm";
import CheckoutDrawerTwo from "./invoice-components/checkout-components/CheckoutDrawerTwo";
import PaymentDrawer from "./invoice-components/checkout-components/PaymentDrawer";
import ClientAdditionalInfo from "./invoice-components/ClientAdditionalInfo";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ProceedToCheckoutButton from "./invoice-components/ProceedToCheckoutButton";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PackageRedeemInvoiceForm from "./invoice-components/PackageRedeemInvoiceForm";
import usefulTip from "../../../assets/tips.png";
import MembershipReedemInvoiceForm from "./invoice-components/MembershipReedemInvoiceForm";
import { splitMembershipDataToArray, validPackageForClient } from "utils/general";
import dayjs from "dayjs";

const NewCreateInvoice = () => {
  const [invoiceDetails, setInvoiceDetails] = useState([]);

  const [invoiceDetailsItem, setInvoiceDetailsItem] = useState({
    employees: [],
  });
  const [client, setClient] = useState(null);
  const [taxValue, setTaxValue] = useState(null);

  const { id } = useParams();

  const invoices = useSelector((state) => state.invoice?.invoices);

  const customerDetailList = useSelector((state) => state.customer.customerDetailList);
  const packagesList  = useSelector((state) => state.catalogue.packagesList);


  const currentInvoice = invoices?.find((item) => item.id === id);

  const editInvoiceClient = customerDetailList?.find(
    (item) => item.id === currentInvoice?.clientId
  );

  useEffect(() => {
    if (id) {
      setInvoiceDetails(
        currentInvoice?.invoiceDetails?.map((item, index) => {
          return {
            uuid: index,
            itemId: item.itemMetaData.id,
            itemPrice: Number(item.itemPrice),
            itemAmount: Number(item.itemAmount),
            itemQuantity: Number(item.itemQuantity),
            total: Number(item.itemAmount) * Number(item.itemQuantity),
            itemType: item?.itemType,
            drawer: { type: "SERVICE_INVOICE" },
            currItem: {
              title: item?.itemTitle,
            },
            employees: item?.employees?.map((emp) => {
              return {
                employeeId: emp.employeeId,
                revenue: 100 / item?.employees?.length,
                commission: 0,
                firstName: emp.employeeMetaData.firstName,
                lastName: emp.employeeMetaData.lastName,
              };
            }),
          };
        })
      );
      setClient(editInvoiceClient);
    } else {
      return;
    }
  }, []);

  const handleInvoice = (name, value) => {
    const temp = invoiceDetailsItem;
    const newinvoiceDetailsItem = { ...temp, [name]: value };
    setInvoiceDetailsItem(newinvoiceDetailsItem);
    return newinvoiceDetailsItem;
  };

  const [drawer, setDrawer] = useState({
    open: false,
    drawerWidth: "0%",
    title: "",
    showIcon: "",
  });

  const handleClose = () => {
    setInvoiceDetailsItem({
      category: null,
      amount: 0,
      quantity: 0,
    });
    setDrawer({
      open: false,
      drawerWidth: "0%",
      title: "",
      showIcon: "",
      type: "",
    });
  };

  // const freePackages =
  //   client &&
  //   client?.packageRelation &&
  //   client?.packageRelation
  //     ?.map((i) => i.package)
  //     ?.flat(1)
  //     ?.map((item) => item.packageItems)
  //     ?.flat(1);

  const handleClientChange = (event, newValue) => {
    setClient(newValue);
    if (!newValue) {
      setInvoiceDetailsItem({
        employees: [],
      });
    }
  };

  const grossPrice = useMemo(() => {
    const value = invoiceDetails?.reduce((a, b) => {
      return (a += b?.total);
    }, 0);
    return value;
  }, [invoiceDetails]);

  // all amounts are just discount and tax amount
  const [allAmounts, setAllAmounts] = useState({
    invoiceDiscount: 0,
    invoiceTaxAmount: 0,
  });

  const handleAllAmounts = (name, value) => {
    const temp = allAmounts;
    const newAllAmounts = { ...temp, [name]: value };
    setAllAmounts(newAllAmounts);
    return newAllAmounts;
  };

  const netPrice =
    grossPrice - allAmounts?.invoiceDiscount + allAmounts?.invoiceTaxAmount;

  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const packageRedeemOptions = useMemo(() => {
    if (client) {
      return client?.invDetailPkgItmRltn
        ?.filter((item) => {
          return item.isRedeemed === 0;
        })
        ?.map((item) => {
          return {
            type: item?.packageItem?.serviceId ? "service" : "product",
            ...item?.packageItem?.itemMetaData,
            packagePriceOfItem: Number(item?.packageItem?.price),
            useFrmPkg: true,
            packageName:packagesList?.find((i)=> i?.id === item?.packageItem?.packageId)?.title
          };
        });
    } else {
      return [];
    }
  }, [client]);

  const membershipRedeemOptions = useMemo(() => {
    if (client) {
      const dateCheck = validPackageForClient(dayjs(client?.membershipPlansRltn?.[0]?.membershipPlan?.createdAt , 'DD-MM-YYYY'), client?.membershipPlansRltn?.[0]?.membershipPlan?.dueDay);
      if(dateCheck){
        const isGreater =  dayjs(dateCheck, "MM-DD-YYYY").isAfter(dayjs());
        const isEqual = dayjs(dateCheck, "MM-DD-YYYY").isSame(dayjs());
        if(isGreater){
          return [];
        }else{
          const check = client?.membershipPlansRltn?.map((el)=>{
            const data = splitMembershipDataToArray(el?.membershipPlan?.discountDetails, el?.membershipPlan?.title);
            return data;
          })
          return check?.flat();
        }
      }else{
        return [];
      }
    } else {
      return [];
    }
  }, [client]);  
  return (
    <>
      <div className="new-create-invoice-wrapper">
        <NewCommonDrawer
          open={drawer.open}
          handleClose={handleClose}
          drawerWidth={drawer.drawerWidth}
          title={drawer.title}
          showIcon={drawer.showIcon}
        >
          {drawer.type === "CHECKOUT_DRAWER" && (
            <CheckoutDrawerTwo
              client={client}
              invoiceDetails={invoiceDetails}
              grossPrice={grossPrice}
              netPrice={netPrice}
              allAmounts={allAmounts}
              handleAllAmounts={handleAllAmounts}
              setDrawer={setDrawer}
              setTaxValue={setTaxValue}
              taxValue={taxValue}
            />
          )}
          {drawer.type === "PAYMENT_DRAWER" && (
            <PaymentDrawer
              client={client}
              invoiceDetails={invoiceDetails}
              grossPrice={grossPrice}
              netPrice={netPrice}
              allAmounts={allAmounts}
              invoiceDate={invoiceDate}
              setDrawer={setDrawer}
            />
          )}

          {drawer.type === "PACKAGE_REDEEM_INVOICE" && (
            <PackageRedeemInvoiceForm
              id={invoiceDetailsItem?.category?.id}
              invoiceDetailsItem={invoiceDetailsItem}
              setInvoiceDetailsItem={setInvoiceDetailsItem}
              handleInvoice={handleInvoice}
              setDrawer={setDrawer}
              packageRedeemOptions={packageRedeemOptions}
              setInvoiceDetails={setInvoiceDetails}
            />
          )}
          {drawer.type === "SERVICE_INVOICE" && (
            <ServiceInvoiceForm
              id={invoiceDetailsItem?.category?.id}
              invoiceDetailsItem={invoiceDetailsItem}
              setInvoiceDetailsItem={setInvoiceDetailsItem}
              handleInvoice={handleInvoice}
              setDrawer={setDrawer}
              setInvoiceDetails={setInvoiceDetails}
            />
          )}
          {drawer.type === "PRODUCT_INVOICE" && (
            <ProductInvoiceForm
              id={invoiceDetailsItem?.category?.id}
              invoiceDetailsItem={invoiceDetailsItem}
              setInvoiceDetailsItem={setInvoiceDetailsItem}
              handleInvoice={handleInvoice}
              setDrawer={setDrawer}
              setInvoiceDetails={setInvoiceDetails}
            />
          )}
          {drawer.type === "PACKAGE_INVOICE" && (
            <PackageInvoiceForm
              id={invoiceDetailsItem?.category?.id}
              invoiceDetailsItem={invoiceDetailsItem}
              setInvoiceDetailsItem={setInvoiceDetailsItem}
              handleInvoice={handleInvoice}
              setDrawer={setDrawer}
              setInvoiceDetails={setInvoiceDetails}
            />
          )}
          {drawer.type === "MEMBERSHIP_INVOICE" && (
            <MembershipInvoiceForm
              id={invoiceDetailsItem?.category?.id}
              invoiceDetailsItem={invoiceDetailsItem}
              setInvoiceDetailsItem={setInvoiceDetailsItem}
              handleInvoice={handleInvoice}
              setDrawer={setDrawer}
              setInvoiceDetails={setInvoiceDetails}
            />
          )}
          {/* {drawer.type === "FreeItemsForm" && (
            <FreeItemsForm
              options={freePackages}
              invoiceDetailsItem={invoiceDetailsItem}
              handleInvoice={handleInvoice}
              setDrawer={setDrawer}
            />
          )} */}
          {drawer.type === "CommisionAndRevenue" && (
            <CommisionAndRevenue
              employees={invoiceDetailsItem?.employees}
              handleInvoice={handleInvoice}
              setInvoiceDetails={setInvoiceDetails}
              setDrawer={setDrawer}
              drawer={invoiceDetailsItem?.drawer}
              setInvoiceDetailsItem={setInvoiceDetailsItem}
            />
          )}

          {drawer.type === "MEMBERSHIP_REDEEM_INVOICE" && (
            <MembershipReedemInvoiceForm
              id={invoiceDetailsItem?.category?.id}
              invoiceDetailsItem={invoiceDetailsItem}
              setInvoiceDetailsItem={setInvoiceDetailsItem}
              handleInvoice={handleInvoice}
              setDrawer={setDrawer}
              membershipRedeemOptions={membershipRedeemOptions}
              setInvoiceDetails={setInvoiceDetails}
            />
          )}
        </NewCommonDrawer>

        <Grid container>
          <Grid item lg={9}>
            <Grid container>
              <Grid item xs={3.6}>
                {/* <div>
                  <h3>Select A Client</h3>
                </div> */}
                <ClientSelectBox
                  handleChange={handleClientChange}
                  client={client}
                  setClient={setClient}
                  invoiceDetails={invoiceDetails}
                />
              </Grid>
              <Grid item xs={2.4}>
                <FormControl
                  fullWidth
                  style={{ padding: "10px 5px 10px 10px" }}
                >
                  <p
                    style={{
                      margin: "0px 0px 4px 0px",
                      fontWeight: "500",

                      color: "#9469FC",
                    }}
                  >
                    INVOICE DATE
                  </p>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      size="small"
                      format="dd/MM/yyyy"
                      name="invoiceDate"
                      value={invoiceDate}
                      disableFuture
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          style: {
                            backgroundColor: "white",
                          },
                          InputProps: {
                            readOnly: true,
                          },
                          onKeyDown: (e) => {
                            e.preventDefault();
                          },
                        },
                      }}
                      // value={formik.values.invoiceDate || new Date()}
                      onChange={(e) => {
                        setInvoiceDate(e);
                        // formik.setFieldValue("invoiceDate", e, true);
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={1}></Grid>

              <Grid item xs={5}>
                <p
                  style={{
                    margin: "10px 0px 4px 0px",
                    fontWeight: "500",
                    color: "#9469FC",
                  }}
                >
                  CHOOSE AN ITEM
                </p>
                <CategoryTypeComponent
                  {...{
                    ourCategoryId: invoiceDetailsItem?.category?.id,
                    handleInvoice,
                    setDrawer,
                    packageRedeemOptions,
                    membershipRedeemOptions:membershipRedeemOptions,
                    client,
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ height: "1rem", paddingTop: "4px" }}>
              {/* <Divider /> */}
            </div>

            <div className="invoice-table-container">
              <p
                style={{
                  margin: "0px 0px 4px 0px",
                  fontWeight: "500",
                  color: "#9469FC",
                }}
              >
                CART ITEMS
              </p>
              <InvoiceItemsTable
                rows={invoiceDetails}
                setInvoiceDetails={setInvoiceDetails}
                setInvoiceDetailsItem={setInvoiceDetailsItem}
                setDrawer={setDrawer}
              />
            </div>
          </Grid>
          <Grid item lg={3}>
            <div
              style={{
                minWidth: 120,
                borderLeft: "1px solid #e3e3e3",
                height: "87vh",
                padding: "5px ",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              {/* {freePackages && freePackages?.length > 0 && (
                <div style={{ marginTop: "10px" }}>
                  <button
                    onClick={() => {
                      setDrawer({
                        type: "FreeItemsForm",
                        drawerWidth: "35%",
                        open: true,
                        title: "Free Items",
                      });
                    }}
                  >
                    Free
                  </button>
                </div>
              )} */}
              {/* {!client && (
                <div>
                  <p
                    style={{
                      margin: "0px 0px 4px 0px",
                      fontWeight: "500",
                      color: "#9469FC",
                    }}
                  >
                    NEW CUSTOMER
                  </p>
                  <button className="create-account-button">
                    <Add />
                    ADD NEW CONTACT
                  </button>
                </div>
              )} */}

              <ClientAdditionalInfo
                client={client}
                setInvoiceDetails={setInvoiceDetails}
              />
              {!client && (
                <div className="useful-tips">
                  <img
                    className="appointment-text-field-start-adornment"
                    src={usefulTip}
                    alt="start-icon"
                  />
                  <ul className="useful-tips-ul">
                    <li>Select Existing client </li>
                    <li> Add New Client from select client dropdown.</li>
                    <li>Add Package / Service</li>
                    <li>Proceed to Checkout</li>
                  </ul>
                </div>
              )}
              <ProceedToCheckoutButton
                {...{ invoiceDetails, setDrawer, grossPrice }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default NewCreateInvoice;
