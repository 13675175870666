// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import axios from '../../utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from 'store/snackbar/slice';
import { userName } from 'utils/general';
import { ENDPOINTS } from 'network/ENDPOINT';
import { fetchUserData } from 'store/users/action';
import { fetchCountryList } from 'store/meta/action';
import { fetchMultiStoreList } from 'store/multi-store/action';
import { authenticate, setCountData, setShowOTPModal } from 'store/auth/slice';
import { getCategory, getMembership, getPackage } from 'store/catalogue/slice';
import { getStaffs, getTargetcommisionList } from 'store/employees/slice';
import { getBussinessSetUpData } from 'utils/general';
import { getDiscountList, getInvoiceData, getPaymentOptionList, getRewardPointsList, getSales, getTaxData } from 'store/invoice/slice';
import { getCustomers } from 'store/customer/slice';
import { getDashboard } from 'store/dashboard/slice';
import dayjs from 'dayjs';

// ----------------------------------------------------------------------

const initialState = {
  has_error: false,
  is_ready_for_otp_validation: false,
  ownerData: false,
  token: false,
  ownerLoading: false,
  currentOutletData: false,
  businessSetupData: null,
  businessSetupTime: null,
  managerData: [],
};

const slice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.has_error = action.payload;
    },

    loginSuccess(state, action) {
      state.is_ready_for_otp_validation = true;
      state.ownerData = action.payload;
      state.token = action.payload?.token ?? null;
      state.currentOutletData =
        action?.payload?.ownerBusiness?.businessLocations?.find((i) => {
          return i?.isPrimaryCompany === true;
        }) || null;
    },

   
    //ADD Manager Data
    managerSuccessData(state, action) {
      state.managerData = action.payload;
    },
    getBusinessDataList(state, action) {
      const businessHours = new Map();
      const businessSetupData = action.payload;
      state.businessSetupData = action.payload;
      // businessSetupData.forEach(bs => {
      //    const day = bs.prefDay;
      //    const timing = {
      //         start: bs.ENDPOINTS
      //    }
      //     if (!businessHours.has(day)) {

      //         day.set(s.employeeId, [shift]);
      //     }else{
      //             schedules.set(s.employeeId, [...schedules.get(s.employeeId),shift])
      //     }
      // })
      state.businessSetupTime = getBussinessSetUpData(action.payload);
    },
    isReadyForOTPValidation(state, action) {
      state.is_ready_for_otp_validation = action.payload;
    },
    //  UPDATE Business Profile
    updateBusinessSuccess(state, action) {
      state.ownerData = {
        ...state.ownerData,
        ...action?.payload,
      };
      state.ownerLoading = false;
    },

    //  UPDATE Business Address
    updateAddressSuccess(state, action) {
      const { companyId } = action.payload;
      const updatedBusinessLocations =
        state.ownerData?.ownerBusiness?.businessLocations.map((location) => {
          if (location.companyId === companyId) {
            return {
              ...location,
              companyAddress: action.payload?.companyAddress,
            };
          }
          return location; // Keep other locations unchanged
        });
      state.ownerData = {
        ...state.ownerData,
        ownerBusiness: {
          ...state.ownerData.ownerBusiness,
          businessLocations: updatedBusinessLocations,
        },
      };
      state.ownerLoading = false;
    },
    hasError(state, action) {
      state.error = action.payload;
      state.ownerLoading = false;
    },
    //  UPDATE Business Profile Image
    updateImageSuccess(state, action) {
      if (action.payload?.company) {
        const updatedData = {
          ...state?.ownerData,
          ownerBusiness: {
            ...state?.ownerData?.ownerBusiness,
            businessLocations:
              state?.ownerData?.ownerBusiness?.businessLocations.map(
                (data, index) =>
                  index === 0
                    ? {
                        ...data,
                        companyImageUrl:
                          action.payload?.company?.companyImageUrl,
                        photo: { ...data?.photo, id: action?.payload?.id },
                      }
                    : data
              ),
          },
        };

        state.ownerData = updatedData;
        state.ownerLoading = false;
      }
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function register(body) {
  return async () => {
    try {
      const response = await axios.post(ENDPOINTS.SIGNUP_API, body);
      dispatch(slice.actions.hasError(false));
      dispatch(
        openSnackbar({
          open: true,
          message: "Your registration has been successfully completed.",
          variant: "alert",
          alert: {
            color: "success",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(true));
      dispatch(
        openSnackbar({
          open: true,
          message: error.message || error.error,
          variant: "alert",
          alert: {
            color: "error",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    }
  };
}
export function login(body) {
    return async () => {
        try {
            const response = await axios.post(ENDPOINTS.LOGIN, body);
            const result = response?.data?.data;
            const ownerId = result?.ownerBusiness?.ownerId;
            const companyId = result?.ownerBusiness?.businessLocations?.find((i)=> {return i?.isPrimaryCompany == true})?.id;
            const countData = result?.companyData;
           
            dispatch(slice.actions.managerSuccessData(result?.ownerBusiness?.businessLocations?.[0]?.manager));
            dispatch(slice.actions.loginSuccess(result));
            dispatch(fetchUserData({ companyId }));
            dispatch(getCategory(companyId));
            dispatch(getPackage(companyId));
            dispatch(getStaffs(companyId));
            dispatch(fetchCountryList());
            dispatch(getBusinessPreferenceList(companyId));
            dispatch(getMembership(companyId));
            dispatch(fetchMultiStoreList(ownerId));
            dispatch(getInvoiceData({companyId}));
            dispatch(
                setCountData ({
                employeeCount: countData?.employeeCount,
                serviceCount: countData?.serviceCount,
                appointmentCount: countData?.appointmentCount,
                bussinessSetUpDataCount: countData?.bussinessSetUpDataCount,
                })
            );
            dispatch(authenticate(true));
            dispatch(getSales({companyId}));
            dispatch(slice.actions.hasError(false));
            dispatch(getTaxData({ companyId }));
            dispatch(getDiscountList({ companyId }));
            dispatch(getPaymentOptionList({ companyId }));
            dispatch(getInvoiceData({companyId: companyId}));
            dispatch(getCustomers({companyId: companyId}));
            dispatch(getDashboard({companyId: companyId, startDate:dayjs().format('YYYY-MM-DD'), endDate:dayjs().format('YYYY-MM-DD')}));
            dispatch(getRewardPointsList({companyId: companyId}));
            dispatch(getTargetcommisionList(companyId));

            

        } catch (error) {
            dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message:  error.message || error.error,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}

export function resetPassword(body) {
  return async () => {
    try {
      const response = await axios.patch(ENDPOINTS.FORGOT_PASSWORD_API, body);
      dispatch(slice.actions.hasError(false));
      dispatch(slice.actions.isReadyForOTPValidation(true));
    } catch (error) {
      dispatch(slice.actions.hasError(true));
      dispatch(
        openSnackbar({
          open: true,
          message: error.message || error.error,
          variant: "alert",
          alert: {
            color: "error",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    }
  };
}

export function getBusinessPreferenceList(companyId) {
  return async () => {
    try {
      const response = await axios.get(
        `${ENDPOINTS.GET_BUSSINESS_SETUP_API}?companyId=${companyId}`
      );
      dispatch(slice.actions.hasError(false));
      dispatch(slice.actions.getBusinessDataList(response.data.data));
      // dispatch(slice.actions.isReadyForOTPValidation (true));
    } catch (error) {
      dispatch(slice.actions.hasError(true));
      dispatch(
        openSnackbar({
          open: true,
          message: error.message || error.error,
          variant: "alert",
          alert: {
            color: "error",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    }
  };
}

export function setUpBussinessAPI(body) {
  return async () => {
    try {
      const response = await axios.post(ENDPOINTS.SET_UP_BUSSINESS_API, body);
      dispatch(slice.actions.hasError(false));
      await dispatch(
        getBusinessPreferenceList(body.companyPreferences[0].companyId)
      );
      dispatch(
        openSnackbar({
          open: true,
          message: "Updated Bussiness Data",
          variant: "alert",
          alert: {
            color: "success",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(true));
      dispatch(
        openSnackbar({
          open: true,
          message: error.message || error.error,
          variant: "alert",
          alert: {
            color: "error",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    }
  };
}

export function initializeOTPValidation() {
  return async () => {
    dispatch(slice.actions.isReadyForOTPValidation(false));
  };
}

export function updatePasswordAPI({ body, companyId }) {
  return async () => {
    try {
      const response = await axios.patch(
        `${ENDPOINTS.UPDATE_PASSWORD_API}/${companyId}`,
        body
      );
      dispatch(
        openSnackbar({
          open: true,
          message: "Your Password has been Updated Successfully.",
          variant: "alert",
          alert: {
            color: "success",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
      dispatch(slice.actions.hasError(false));
    } catch (error) {
      dispatch(slice.actions.hasError(true));
      dispatch(
        openSnackbar({
          open: true,
          message: error.error || error.message,
          variant: "alert",
          alert: {
            color: "error",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    }
  };
}

// edit profile

export function editBusiness({ body, address }) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${ENDPOINTS.EDIT_BUSINESS_API}`, body);

      if (response && response.data) {
        dispatch(slice.actions.updateBusinessSuccess(response.data.data));
        const addressId =
          response?.data?.data?.ownerBusiness?.businessLocations[0]
            ?.companyAddress?.id;
        const companyId =
          response?.data?.data?.ownerBusiness?.businessLocations[0]
            ?.companyAddress?.companyId;
        if (address) {
          dispatch(updateBussinessAddress({ ...address, id: addressId }));
        }
        dispatch(
          openSnackbar({
            open: true,
            message: "Your Profile has been Updated Successfully.",
            variant: "alert",
            alert: {
              color: "success",
            },
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            close: true,
          })
        );
        dispatch(slice.actions.hasError(false));
      } else {
        throw new Error("Failed to update profile.");
      }
    } catch (error) {
      // Handle errors, including error.response.data for API error messages
      dispatch(slice.actions.hasError(true));
      dispatch(
        openSnackbar({
          open: true,
          message:
            error.response?.data?.message ||
            error.message ||
            "An error occurred while updating the profile.",
          variant: "alert",
          alert: {
            color: "error",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    }
  };
}

export function updateBussinessAddress(address) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${ENDPOINTS.BUSINESS_ADDRESS}`,
        address
      );
      if (response && response.data) {
        dispatch(slice.actions.updateAddressSuccess(response.data.data));
      } else {
        throw new Error("Failed to update profile.");
      }
    } catch (error) {
      // Handle errors, including error.response.data for API error messages
      dispatch(slice.actions.hasError(true));
      dispatch(
        openSnackbar({
          open: true,
          message:
            error.response?.data?.message ||
            error.message ||
            "An error occurred while updating the profile.",
          variant: "alert",
          alert: {
            color: "error",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    }
  };
}

export function updateProfileImage(image) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${ENDPOINTS.UPDATE_BUSINESS_PROFILE}`,
        image
      );
      if (response && response.data) {
        dispatch(slice.actions.updateImageSuccess(response.data.data));
      } else {
        throw new Error("Failed to update profile.");
      }
    } catch (error) {
      // Handle errors, including error.response.data for API error messages
      dispatch(slice.actions.hasError(true));
      dispatch(
        openSnackbar({
          open: true,
          message:
            error.response?.data?.message ||
            error.message ||
            "An error occurred while updating the profile.",
          variant: "alert",
          alert: {
            color: "error",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    }
  };
}

export function ManagerLoginAPI(body) {
  return async () => {
    try {
      const response = await axios.post(`${ENDPOINTS.MANAGER_LOGIN}`, body);
      if (response?.data?.success) {
        dispatch(slice.actions.managerSuccessData(response.data.data));
      } 
      dispatch(
        openSnackbar({
          open: true,
          message: "Your Password has been Updated Successfully.",
          variant: "alert",
          alert: {
            color: "success",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
      dispatch(slice.actions.hasError(false));
    } catch (error) {
      dispatch(slice.actions.hasError(true));
      dispatch(
        openSnackbar({
          open: true,
          message: error.error || error.message,
          variant: "alert",
          alert: {
            color: "error",
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          close: true,
        })
      );
    }
  };
}
