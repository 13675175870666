import React from "react";
import PropTypes from "prop-types";

// material-ui
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// material-ui
// third-party

// project imports
import MainCard from "ui-component/cards/MainCard";
import { useAppSelector as useSelector } from "store";
// assets
import dayjs from "dayjs";
import { capitalize } from "utils/general";
import { TableHead, alpha, useTheme } from "@mui/material";
import { v4 as uuid } from "uuid";
import { useCountyPrice } from "components/hooks/useCountyPrice";
import { ThemeMode } from "config";

const InvoiceHistoryTable = ({ clientDetail }) => {
  const theme = useTheme();
  const { invoices } = useSelector((state) => state.invoice);
  const { format } = useCountyPrice();
  const clientInvoice = invoices?.filter?.(
    (i) => i?.clientId === clientDetail?.id
  );

  const statusColor = {
    pending:{
      label:'In Progress',
      color : 'warning.dark',
      chipcolor :'warning.light',
    },
    completed:{
      label:'Invoice Generated',
      color : 'success.dark',
      chipcolor : alpha(theme.palette.success.light, 0.6),
    }
  }
  return (
    <MainCard content={false} sx={{borderRadius:"0px !important"}}>
      {/* table */}
      {/* {loading && ( <Loader /> )} */}
      <TableContainer>
        <Table
          sx={{ minWidth: 750, border:"1px solid #e9e9e9" }}
          aria-labelledby="tableTitle"
          className="invoice-main-table"
        >
          <TableHead sx={{background:"#e3f2fd"}}>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Invoice No</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Discount</TableCell>

              <TableCell align="right">Gross Price</TableCell>
              <TableCell align="right">Net Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientInvoice.map((row, index) => (
              <TableRow
                key={uuid()}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {/* {row.name} */}
                  {dayjs(row.invoiceDate).format("DD-MM-YYYY")}
                  {/* {userName(row?.firstName, row?.lastName)} */}
                </TableCell>
                <TableCell> {row.invoiceNo ? row.invoiceNo : "-"}</TableCell>
                <TableCell>
                  <Chip
                    label={statusColor[row.status]?.label}
                    size="small"
                    sx={{
                      bgcolor: statusColor[row.status]?.chipcolor,
                      color: statusColor[row.status]?.color,
                      cursor: "pointer",
                    }}
                    // className={className}
                  />
                </TableCell>
                <TableCell>{row?.discountType === "percentage" ? `${row.invoiceDiscount}%` : row.invoiceDiscount}</TableCell>
                <TableCell align="right">{format(row.grossPrice)}</TableCell>
                <TableCell align="right">{format(row.netPrice)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

InvoiceHistoryTable.propTypes = {
  clientDetail: PropTypes.object,
};

export default InvoiceHistoryTable;
