import React from 'react'
import OrderSummaryView from './OrderSummaryView';
import { Divider, Grid, InputLabel } from '@mui/material';
import { useCountyPrice } from 'components/hooks/useCountyPrice';

const PaymentDrawerLeftSection = ({
  grossPrice,
  allAmounts,
  netPrice,
  paymentMethodsUsed,
  pendingAmount,
}) => {
    const { format } = useCountyPrice();
  return (
    <>
      <Grid item xs={5.7}>
        <OrderSummaryView
          {...{
            grossPrice,
            invoiceDiscount: allAmounts.invoiceDiscount,
            invoiceTaxAmount: allAmounts.invoiceTaxAmount,
            netPrice,
          }}
        />
        <div className="mt-4">
          <h3>AMOUNT SPLIT</h3>
          <div className="customer-info-checkout-parent-container">
            {paymentMethodsUsed?.map((item, index) => {
              return (
                <div className="checkout-item" key={index}>
                  <div>
                    <InputLabel>{item.mode}</InputLabel>
                  </div>
                  <InputLabel> {format(item?.amount)} </InputLabel>
                </div>
              );
            })}
            <Divider />
            <div className="checkout-item" style={{ color: "#e68e8f" }}>
              <div>
                <label>Pending Amount</label>
              </div>
              <label> {format(Math.floor(pendingAmount))} </label>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={0.3}>
        <div className="vertical-line"></div>
      </Grid>
    </>
  );
};

export default PaymentDrawerLeftSection