// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { ENDPOINTS } from 'network/ENDPOINT';
import dayjs from 'dayjs';
import { openSnackbar } from 'store/snackbar/slice';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    events: [],
    event: {},
    resource: {},
    resources: [],
    isFetching: false,
};
const eventColor = {
    'pending' : '#faad14',
    'canceled' : '#f5222d',
    'completed' : '#51db58',
    'clientrejected' : '#4998de',
}

const slice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET EVENTS
        getEventsSuccess(state, action) {
            const events = action.payload;
            const eventData = [];
            const resourceData = [];
            events?.map((e) => {

                e.servicesBooked.map((s, key) => {
                  
                    let color;
                    switch(e?.status){
                        case 'pending' :
                            color='#faad14';
                            break;
                        case 'canceled':
                            color = '#f5222d';
                            break;
                        case 'completed' :
                            color='#51db58';
                            break;
                        case 'clientrejected' :
                            color='#4998de';
                            break;
                    }
                    eventData.push({
                        "resourceId": s?.employeeBooked?.id,
                        "staff": s?.employeeBooked?.id,
                        "service": s?.svcCtlgItemsData?.id,
                        // "description":"",
                        "client": e?.clientId,
                        "id": e?.id,
                        "allDay": false,
                        "color": eventColor[e?.status],//color,//backgroundColor[Math.floor(Math.random() * backgroundColor.length)]['value'],
                        "description": e.comments?.[0]?.content || s?.svcCtlgItemsData?.title,
                        "start": new Date(`${e.appointmentDate}T${e.startTime}`).toISOString(),
                        "end": new Date(`${e.appointmentDate}T${e.endTime}`).toISOString(),
                        "title": s?.svcCtlgItemsData?.title,
                        "status": e?.status,
                        "price": s?.svcCtlgItemsData?.price,
                        "duration": e?.totalDuration,
                        "invoiceId":e?.invoice?.id
                    })
                })
            })
            state.events = eventData;
            state.resources = resourceData;
            state.event = undefined;
            state.resource = undefined
            state.isFetching = false
        },

        // ADD EVENT
        addEventSuccess(state, action) {
            //state.events = 
            const n_servicesBooked = action.payload?.servicesBooked[0];
            const n_e = action.payload;
            const updatedEvents = state.events?.map((e)=> {
                if(e.color=='#ef6e23'){
                    e.color=eventColor[e?.status];
                }
                return e;
            })
            updatedEvents.push({
                "resourceId": n_servicesBooked?.employeeBooked?.id,
                "staff": n_servicesBooked?.employeeBooked?.id,
                "service": n_servicesBooked?.svcCtlgItemsData?.id,
                "description": "",
                "client": n_e?.clientId,
                "id": n_e.id,
                "allDay": false,
                 "color": '#ef6e23',
                "description": n_e.comment,
                "start": new Date(`${n_e.appointmentDate}T${n_e.startTime}`).toISOString(),
                "end": new Date(`${n_e.appointmentDate}T${n_e.endTime}`).toISOString(),
                "title": n_servicesBooked?.svcCtlgItemsData?.title,
                "status": n_e?.status,
                "price": n_servicesBooked?.svcCtlgItemsData?.price,
                "duration": n_e?.totalDuration
            })



            /*e.servicesBooked.map((s, key) => {

                eventData.push({
                    "resourceId": s?.employeeBooked?.id,
                    "staff": s?.employeeBooked?.id,
                    "service": s?.svcCtlgItemsData?.id,
                    "description": "",
                    "client": e?.clientId,
                    "id": e.id,
                    "allDay": false,
                     "color": '#85d837',
                    "description": e.comment,
                    "start": new Date(`${e.appointmentDate}T${e.startTime}`).toISOString(),
                    "end": new Date(`${e.appointmentDate}T${e.endTime}`).toISOString(),
                    "title": s?.svcCtlgItemsData?.title
                })
            })*/
            state.events = updatedEvents;
            //state.resources = [...state.resources, resourceData]
            //state.event = eventData;
            //state.resource = resourceData;
            state.isFetching = false;
            
        },

        // UPDATE EVENT
        updateEventSuccess(state, action) {
            const n_servicesBooked = action.payload?.servicesBooked[0];
            const n_e = action.payload;
            const updatedEvents = state.events?.map((e)=> {
                if(e.color=='#ef6e23'){
                   e.color=eventColor[e?.status];
                }
                if(e?.id === n_e?.id){
                    return {
                        "resourceId": n_servicesBooked?.employeeBooked?.id,
                        "staff": n_servicesBooked?.employeeBooked?.id,
                        "service": n_servicesBooked?.svcCtlgItemsData?.id,
                        "description": "",
                        "client": n_e?.clientId,
                        "id": e.id,
                        "allDay": false,
                        "color": '#ef6e23',
                        "description": n_e.comment,
                        "start": new Date(`${n_e.appointmentDate}T${n_e.startTime}`).toISOString(),
                        "end": new Date(`${n_e.appointmentDate}T${n_e.endTime}`).toISOString(),
                        "status": n_e?.status,
                        "title": n_servicesBooked?.svcCtlgItemsData?.title,
                        "invoiceId":n_e?.invoice?.id,
                        "price": n_servicesBooked?.svcCtlgItemsData?.price,
                        "duration": n_e?.totalDuration
                    }
                }
                return e;
            })
            //state.events = [...state.events, ...eventData];
            state.events = updatedEvents
            state.isFetching = false

        },

        // REMOVE EVENT
        removeEventSuccess(state, action) {
            state.events = action.payload;
        },

        updateCalendarEvents(state, action) {

        },
        getCalendarEvents(state, action) {

        },
        //SET ISFETCHING
        setIsFetching(state, action) {
            state.isFetching = action.payload
        }

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getEvents({
    companyId,
    appointmentDate,
    appointmentDateRange,
    emplyEmail,
    url,
    navigate,
    pagination,
    limit
}) {
    return async () => {
        try {
            dispatch(slice.actions.setIsFetching(true));
            const api_signature = `${ENDPOINTS.GET_APPOINTMENT}?companyId=${companyId}${appointmentDate ? "&appointmentDate=" + appointmentDate : ""
                }${appointmentDateRange && appointmentDateRange?.length > 0
                    ? "&appointmentDateRange=" + appointmentDateRange
                    : []
                }${limit
                    ? "&limit=" + limit
                    : ''
                }${emplyEmail
                    ? "&emplyEmail=" + emplyEmail
                    : ''
                }
              ${url == undefined ? "" : url}${pagination ? pagination : ""}`
            const response = await axios.get(api_signature);
            dispatch(slice.actions.getEventsSuccess(response.data?.data?.appointment));
        } catch (error) {
            dispatch(slice.actions.setIsFetching(false));
            dispatch(slice.actions.hasError(error));
        }


        /*try {
            const response = await axios.get('https://mock-data-api-nextjs.vercel.app/api/calendar/events');
            dispatch(slice.actions.getEventsSuccess(response.data.events));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }*/
    };
}

export function addEvent({event, clientData}) {
    return async () => {
        try {
            dispatch(slice.actions.setIsFetching(true));
            const response = await axios.post(ENDPOINTS.ADD_APPOINTMENT, event);
            dispatch(slice.actions.addEventSuccess(response.data?.data));
            const message = `
                *Appointment Booked*
                Hi *${clientData?.name}*
                Thank you for your appointment at ${clientData?.title} on ${clientData.startTime}.
                Please check the appointment details.
                ${window.location.origin}/view-appointment?id=${response.data?.data?.id}&bussinessId=${clientData?.mainBusinesId}
                `;

            window.open(`https://wa.me/+91${clientData?.phoneNumber}?text=${encodeURIComponent(message)}`);
            //dispatch(getEvents({companyId:event.companyId,appointmentDate:dayjs().format('YYYY-MM-DD')}))
        } catch (error) {
            dispatch(slice.actions.setIsFetching(false));
            dispatch(slice.actions.hasError(error));
            dispatch(openSnackbar ({
                open: true,
                message:  error.message || error.error,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}

export function updateEvent(event) {
    return async () => {
        try {
            dispatch(slice.actions.setIsFetching(true));
            const response = await axios.post(ENDPOINTS.ADD_APPOINTMENT, event);

            //Need to update the logic to push/update the updated event into the existing list of state events
            //const from = dayjs().startOf('month').format('YYYY-MM-DD');
            //const end = dayjs().endOf('month').format('YYYY-MM-DD');
            //dispatch(getEvents({companyId:event.companyId,appointmentDate:dayjs().format('YYYY-MM-DD')}))
            //dispatch(getEvents({ companyId: event.companyId, appointmentDateRange: `[${from},${end}]`, limit: 500 }));
            dispatch(slice.actions.updateEventSuccess(response.data.data));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.setIsFetching(false));
            dispatch(slice.actions.hasError(error));
            dispatch(openSnackbar ({
                open: true,
                message:  error.message || error.error,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}

export function removeEvent(eventId) {
    return async () => {
        try {
            const response = await axios.post('/api/calendar/events/delete', { eventId });
            dispatch(slice.actions.removeEventSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
