import React from "react";
import { useSelector } from "react-redux";
import ItemAutoComplete from "./ItemAutoComplete";
import { Box, Button, Divider, Grid, TextField, styled } from "@mui/material";
import SelectEmployeeAutoComplete from "./SelectEmployeeAutoComplete";
import { lighten, darken } from "@mui/material";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const ServiceInvoiceForm = ({
  invoiceDetailsItem,
  setInvoiceDetailsItem,
  handleInvoice,
  setDrawer,
  setInvoiceDetails,
}) => {
  const { serviceList } = useSelector((state) => state.catalogue);

  const handleEmployeeSelect = (employees) => {
    handleInvoice("employees", employees);
  };

  const serviceListOptions = serviceList
    ?.map((item) => {
      const category = item?.title?.toUpperCase();
      return item.svcCtlgItems?.map((i)=> {
        return ({...i, category})
      });
    })
    ?.flat(1);
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <ItemAutoComplete
            label="Item Name"
            id="grouped-item"
            options={serviceListOptions}
            value={invoiceDetailsItem.currItem}
            getOptionLabel={(option) => {
              return option.title;
            }}
            groupBy={(option) => option?.category?.toUpperCase()}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            handleChange={(event, currItem) => {
              if (currItem) {
                setInvoiceDetailsItem((prev) => {
                  return {
                    ...prev,
                    itemPrice: currItem?.price,
                    itemAmount: Number(currItem?.price),
                    itemId: currItem?.id,
                    itemQuantity: 1,
                    currItem: currItem,
                    itemType: "service",
                  };
                });
              }
            }}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <div style={{ marginTop: "5px" }}>
            <label>Amount for one item</label>
            <TextField
              sx={{ mt: 1 }}
              variant="outlined"
              fullWidth
              type="number"
              value={invoiceDetailsItem?.itemAmount}
              onChange={(event) => {
                handleInvoice("itemAmount", Number(event.target.value));
              }}
              InputProps={{
                startAdornment: <span>₹</span>,
              }}
              required
            />
          </div>
        </Grid>
        <Grid item sm={12} md={6}>
          <div style={{ marginTop: "5px" }}>
            <label>Quantity</label>
            <TextField
              sx={{ mt: 1 }}
              variant="outlined"
              fullWidth
              type="number"
              value={invoiceDetailsItem?.itemQuantity}
              onChange={(event) => {
                handleInvoice("itemQuantity", Number(event.target.value));
              }}
              required
            />
          </div>
        </Grid>
        <Grid item sm={12} md={6}>
          <div style={{ marginTop: "5px" }}>
            <label>Select Employee</label>
            <SelectEmployeeAutoComplete
              invoiceDetailsItemEmployees={invoiceDetailsItem?.employees}
              handleChange={handleEmployeeSelect}
            />
          </div>
        </Grid>
      </Grid>

      <br />
      <br />

      <Divider />

      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        marginTop="30px"
      >
        <div>
          TOTAL : ₹{" "}
          {invoiceDetailsItem.itemAmount * invoiceDetailsItem.itemQuantity || 0}
        </div>
        <div style={{ display: "flex", gap: "5px" }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setDrawer({ open: false });
              setInvoiceDetailsItem(null);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            // disable if no employee is choosen
            // disabled={invoiceDetailsItem?.employees?.length < 1 ? true : false}
            onClick={() => {
              if (
                !invoiceDetailsItem?.employees ||
                invoiceDetailsItem?.employees?.length === 0
              ) {
                const rowData = handleInvoice("employees", []);
                setInvoiceDetails((prev) => {
                  return [
                    ...prev,
                    {
                      ...rowData,
                      total:
                        invoiceDetailsItem.itemAmount *
                        invoiceDetailsItem.itemQuantity,
                      uuid: new Date(),
                    },
                  ];
                });
                setDrawer({ open: false });
                setInvoiceDetailsItem(null);
              } else {
                setDrawer({
                  open: true,
                  drawerWidth: "40%",
                  title: "Revenue and Commission",
                  showIcon: "",
                  type: "CommisionAndRevenue",
                });

                const data = {
                  ...invoiceDetailsItem,
                  total:
                    invoiceDetailsItem.itemAmount *
                    invoiceDetailsItem.itemQuantity,
                  employees: invoiceDetailsItem?.employees?.map((item) => {
                    return {
                      ...item,
                      revenue: (
                        100 / invoiceDetailsItem?.employees?.length
                      ).toFixed(2),
                      commission: 0,
                    };
                  }),
                };
                setInvoiceDetailsItem(data);
              }
            }}
          >
            PROCEED
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default ServiceInvoiceForm;
