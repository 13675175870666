import React, { useState } from "react";
import PendingPaymentComponent from "pages/clients/ContactList/PendingPaymentComponent";
import { AlternateEmail, Person, PhoneInTalk } from "@mui/icons-material";
import TourIcon from "@mui/icons-material/Tour";
import StarsIcon from '@mui/icons-material/Stars';
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import WalletIcon from "@mui/icons-material/Wallet";
import { Tooltip } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import { CustomerHistory } from "pages/clients/ContactList/CustomerHistory";
import { TopupMembershipForm } from "components/invoice/Genetate Invoice/TopupMembershipForm";
import { useCountyPrice } from "components/hooks/useCountyPrice";

const ClientAdditionalInfo = ({
  client = null,
  showHistory = true,
  setInvoiceDetails = undefined,
}) => {
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);

  const getPendingAmount = (val) =>
    val?.reduce((acc, item) => {
      if (item.paymentMethod.title === "Pending") {
        return (acc += Number(item.amount));
      } else {
        return acc;
      }
    }, 0);
  const pendingAmount = client?.clientInvoicePaymentMethods
    ? getPendingAmount(client?.clientInvoicePaymentMethods)
    : 0;

  const [pendingPopup, setPendingPopup] = React.useState({
    open: false,
    data: null,
  });

  const [topupWalletModal, setTopupWalletModal] = useState(false);
  const { format } = useCountyPrice();

  return (
    <div>
      <TopupMembershipForm
        open={topupWalletModal}
        onClose={() => setTopupWalletModal(false)}
        selectedClient={client}
        handleAddItem={(value) => {
          const newEmployeesArray =
            value?.assignedStaffs?.length > 0
              ? value?.assignedStaffs?.map((item) => {
                  return {
                    employeeId: item.id,
                    firstName: item.firstName,
                    lastName: item.lastName,
                    revenue: 100,
                    commission: 0,
                  };
                })
              : [];
          setInvoiceDetails &&
            setInvoiceDetails((prev) => {
              return [
                ...prev,
                {
                  uuid: new Date(),
                  itemId: value.id,
                  itemPrice: Number(value.price),
                  itemAmount: Number(value.price),
                  itemQuantity: 1,
                  total: Number(value.price),
                  itemType: value?.selectedCategoryType,
                  drawer: { type: "MEMBERSHIP_TOPUP_INVOICE" },
                  currItem: {
                    title: `${value?.title}  [${format(value?.topupValue)}]`,
                  },
                  employees: newEmployeesArray,
                },
              ];
            });
          // handleAddItem(value);
          setTopupWalletModal(false);
        }}
      />
      <PendingPaymentComponent
        {...{
          client: client,
          clientInvoicePaymentMethods: client?.clientInvoicePaymentMethods,
          pendingPopup,
          setPendingPopup,
        }}
      />
      {client && (
        <div className="customer-info-checkout-parent-container pl-2 pt-4">
          <div className="customer-items-in-cart">
            <h4>Customer Information</h4>
          </div>
          <div>
            <div className="customer-info-checkout-container">
              <span>
                <Person sx={{ color: "#BF55FE" }} />
              </span>
              <span>{client?.firstName || "N/A"} </span>
            </div>
            <div className="customer-info-checkout-container">
              <span>
                <AlternateEmail />
              </span>
              <span>{client?.email || "N/A"}</span>
            </div>
            <div className="customer-info-checkout-container">
              <span>
                <PhoneInTalk />
              </span>
              <span>{client?.phoneNumber || "N/A"}</span>
            </div>
            <div className="customer-info-checkout-container">
              <span className="pointer">
                <Tooltip title="Total Visits">
                  <TourIcon sx={{ color: "#2096F3" }} />
                </Tooltip>
              </span>
              <span>{client?.totalVisits || "N/A"}</span>
            </div>
            <div className="customer-info-checkout-container">
              <span className="pointer">
                <Tooltip title="Reward Points">
                  <StarsIcon sx={{ color: "green" }} />
                </Tooltip>
              </span>
              <span>{client?.royaltyPoints?.balance || "N/A"}</span>
            </div>
            <div className="customer-info-checkout-container">
              <span className="pointer">
                <Tooltip title="Wallet Balance">
                  <WalletIcon sx={{ color: "#D4AF37" }} />
                </Tooltip>
              </span>
              <span>{client?.wallets?.balance || "N/A"}</span>
              {client?.wallets && (
                <button
                  className="customButton"
                  onClick={() => {
                    setTopupWalletModal(true);
                  }}
                >
                  Topup
                </button>
              )}
            </div>
            {client && showHistory && (
              <>
                <br />

                <div
                  className="customer-info-checkout-container pointer"
                  style={{ color: "#2096F3" }}
                  onClick={() => {
                    setOpenHistoryDialog(true);
                  }}
                >
                  <span>
                    <HistoryIcon />
                  </span>
                  <span style={{ fontWeight: "500" }}>View History</span>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {client && Boolean(pendingAmount) && (
        <div className="customer-info-checkout-parent-container mt-4">
          {pendingAmount && pendingAmount > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="customer-info-checkout-container pl-2">
                <span className="pointer">
                  <Tooltip title="Pending Amount">
                    <AccountBalanceWalletIcon sx={{ color: "red" }} />
                  </Tooltip>
                </span>
                <span>{pendingAmount || "N/A"}</span>
              </div>

              <button
                className="customButton"
                onClick={() =>
                  setPendingPopup({
                    open: true,
                    data: client,
                    type: "pendinglist",
                  })
                }
              >
                Clear
              </button>
            </div>
          )}
        </div>
      )}

      <CustomerHistory
        open={openHistoryDialog}
        onClose={() => setOpenHistoryDialog(false)}
        clientDetail={client}
      />
    </div>
  );
};

export default ClientAdditionalInfo;
